import {
	CAMPAIGNS,
	GETALLUSERS,
	SAVETITULARDATA,
	SAVEBENEFICIARIESDATA,
	USERLOGS,
	SEARCHUSERLOGS,
	UPDATEPAYMENTCUSTOMER
} from '../actionTypes';
import Common from '../../src/services/common.js';

const FileDownload = require('js-file-download');

export function updateRegistry(values) {
	return async (dispatch) => {
		return Common.update_registry({
			id: values.id,
			status: values.status
		})
			.then(async (res) => {
				if (values.index) {
					await dispatch({
						type: UPDATEPAYMENTCUSTOMER,
						payload: {
							values: {
								index: values.index,
								status: values.status,
								data: values.data
							}
						}
					});
				}
				return {
					spinnerButton: false,
					updated: true,
					messageAlert: 'Registro actualizado exitosamente.',
					openAlert: true
				};
			})
			.catch((e) => {
				return {
					spinnerButton: false,
					openAlert: true,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'Hubo un error al actualizar el pago, intente nuevamente.'
				};
			});
	};
}

// GET LOGS USER OPERATORS
export function userLogs() {
	return async (dispatch) => {
		return await Common.all_users_logs()
			.then(async (res) => {
				await dispatch({
					type: USERLOGS,
					payload: {
						values: {
							data: res.data,
							isSearchUser: false,
							spinnerScreen: false
						}
					}
				});
				let resultLogs = {
					resultLogs: res.data,
					newSearchLogs: false,
					spinnerScreen: false
				};
				return resultLogs;
			})
			.catch((e) => {
				return {
					spinnerScreen: false,
					openAlert: true,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'Hubo un error al procesar su solicitud, intente nuevamente.'
				};
			});
	};
}

export function searchUserLogs(values) {
	return async (dispatch) => {
		return await Common.user_logs_search(values)
			.then(async (res) => {
				await dispatch({
					type: SEARCHUSERLOGS,
					payload: {
						values: {
							data: res.data,
							isSearchUser: true
						}
					}
				});
				let resultLogs = {
					spinnerProgress: false,
					resultLogs: res.data,
					newSearchLogs: true
				};
				if (res.data.length === 0) {
					resultLogs.openAlert = true;
					resultLogs.messageAlert = 'La búsqueda no existe';
				}
				return resultLogs;
			})
			.catch((e) => {
				return {
					openAlert: true,
					spinnerProgress: false,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'Hubo un error al procesar la búsqueda, intente nuevamente.'
				};
			});
	};
}

export function exportData(values) {
	return async () => {
		return await Common.export_data({ responseType: 'blob' }, values)
			.then((res) => {
				FileDownload(res.data, 'data.xlsx');
				return {
					spinnerButton: false
				};
			})
			.catch((e) => {
				return {
					spinnerButton: false,
					openAlert: true,
					messageAlert: 'Hubo un error al exportar la data, intente nuevamente.'
				};
			});
	};
}

export function saveCampaigns(values) {
	return async (dispatch) => {
		await dispatch({
			type: CAMPAIGNS,
			payload: {
				values
			}
		});
		return true;
	};
}

// USERS

export function findAllUsers() {
	return async (dispatch) => {
		return await Common.find_all_users()
			.then(async (res) => {
				await dispatch({
					type: GETALLUSERS,
					payload: {
						values: {
							data: res.data,
							isSearch: false,
							spinnerScreen: false
						}
					}
				});
				let data = {
					data: res.data,
					spinnerScreen: false
				};
				return data;
			})
			.catch((e) => {
				return {
					spinnerScreen: false,
					openAlert: true
					// messageAlert: e.response && e.response.data ?  e.response.data.error.message : 'Hubo un error al consultar los usuarios, intente nuevamente.',
				};
			});
	};
}

export function findClients() {
	return async (dispatch) => {
		return await Common.find_clients()
			.then(async (res) => {
				return {
					clients: res.data.clients,
					spinnerScreen: false
				};
			})
			.catch((e) => {
				return {
					spinnerScreen: false,
					openAlert: true,
					messageAlert:
						e === typeof 'object' && e.response && e.response.data
							? e.response.data.error.message
							: 'Hubo un error al consultar los usuarios, intente nuevamente.'
				};
			});
	};
}

export function searchClient(values) {
	return async (dispatch) => {
		return await Common.search_client(values)
			.then(async (res) => {
				if (res.data.length === 0) {
					return {
						openAlert: true,
						spinnerScreen: false,
						messageAlert: 'La búsqueda no existe'
					};
				}
				return {
					clients: res.data,
					spinnerScreen: false
				};
			})
			.catch((e) => {
				return {
					openAlert: true,
					spinnerScreen: false,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'Hubo un error al procesar la búsqueda, intente nuevamente.'
				};
			});
	};
}

// CHANGE CAMPAIGN

export const saveTitularData = (name, value) => {
	return async (dispatch) => {
		return await dispatch({
			type: SAVETITULARDATA,
			payload: {
				values: {
					data: {
						name,
						value
					}
				}
			}
		});
	};
};

export function saveBeneficiariesData(values) {
	return async (dispatch) => {
		await dispatch({
			type: SAVEBENEFICIARIESDATA,
			payload: {
				values
			}
		});
		return true;
	};
}

// SET CJ COOKIE
export function setCJCookie(params) {
	return async (dispatch) => {
		return await Common.set_cj_cookie(params)
			.then(async (res) => {
				return true;
			})
			.catch((e) => {
				return {
					spinnerScreen: false,
					openAlert: true,
					messageAlert: e?.response?.data?.error?.message
						? e.response.data.error.message
						: 'Se produjo un error al guardar la cookie.'
				};
			});
	};
}

export function downloadFile(data) {
	return async () => {
		return await Common.custom_file_download({ responseType: 'blob' }, data)
			.then((res) => {
				FileDownload(res.data, data.fileName);
				return {
					spinnerScreen: false,
					success: true
				};
			})
			.catch((e) => {
				return {
					openAlert: true,
					messageAlert:
						'Hubo un error al realizar la descarga, intente nuevamente.'
				};
			});
	};
}
