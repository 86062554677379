/* eslint-disable quotes */
import libphonenumber from 'google-libphonenumber';

const alphanumeric = (text) =>
	/^([a-zA-Z0-9ñÑáéíóúÁÉÍÓÚüÜ #_,-.\//¡!¿?()[\]/\n]{3,})$/.test(text);

const DEFAULT_CODE_TLF = process.env.REACT_APP_DEFAULT_CODE_TLF;

const validateFieldHeadLine = (
	titular,
	saveState,
	field,
	value,
	name = 'titular'
) => {
	let formErrors = { ...titular.formErrors };
	let validate = false;
	switch (field) {
		case 'first_name':
			validate = /^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ]{3,})$/.test(value);
			!validate
				? (formErrors.first_name = 'Enter a valid name.')
				: delete formErrors.first_name;
			break;

		case 'last_name':
			validate = /^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ]{3,})$/.test(value);
			!validate
				? (formErrors.last_name = 'Enter a valid last name.')
				: delete formErrors.last_name;
			break;

		case 'nationality':
			validate = /^[a-zA-Z\- \/_?':.,\s]+$/.test(value) && value !== 'default';
			!validate
				? (formErrors.nationality = 'Select type of ID number')
				: delete formErrors.nationality;
			break;

		case 'civil_status':
			validate =
				/^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ]{3,})$/.test(value) && value !== 'default';
			!validate
				? (formErrors.civil_status = 'Select civil status.')
				: delete formErrors.civil_status;
			break;

		case 'dni':
			if (titular?.nationality === "Driver's License")
				validate = /^([a-zA-Z0-9]{11})$/.test(value);
			else if (titular?.nationality === 'Pag-Ibig')
				validate = /^([a-zA-Z0-9]{12})$/.test(value);
			else if (titular?.nationality === 'Passport')
				validate = /^([a-zA-Z0-9]{9,})$/.test(value);
			else if (titular?.nationality === 'SSS')
				validate = /^([a-zA-Z0-9]{10})$/.test(value);
			else if (titular?.nationality === 'TIN')
				validate = /^(\d{9})$/.test(value);
			else if (titular?.nationality === 'UMID') validate = !!value;
			else if (titular?.nationality === 'PRC ID')
				validate = /^(\d{7})$/.test(value);
			else if (titular?.nationality === "Voter's ID")
				validate = /^([a-zA-Z0-9]{22})$/.test(value);
			else if (titular?.nationality === 'OWWA e-card')
				validate = /^(\d{11})$/.test(value);
			else if (titular?.nationality === 'Postal ID')
				validate = /^(\d{12})$/.test(value);
			else if (titular?.nationality === 'Senior Citizen ID')
				validate = /^(\d{4,5})$/.test(value);
			else validate = /^([a-zA-Z0-9]{3,20})$/.test(value);
			!validate
				? (formErrors.dni = 'Enter a valid ID number.')
				: delete formErrors.dni;
			break;

		case 'doc_dni':
			const type = value && value.type ? value.type : null;
			!(
				type === 'image/jpeg' ||
				type === 'image/jpg' ||
				type === 'image/png' ||
				type === 'application/pdf'
			) || type === null
				? (formErrors.doc_dni = 'You must attach a valid identity document.')
				: delete formErrors.doc_dni;
			break;

		case 'sex':
			validate = /^([FM]{1})$/.test(value) && value !== 'default';
			!validate ? (formErrors.sex = 'Select gender.') : delete formErrors.sex;
			break;

		case 'birth_date':
			const maxDate = new Date(
				new Date().setFullYear(new Date().getFullYear() - 18)
			);
			let date = value ? new Date(value) : null;
			let isDate = isValidDate(date);
			!isDate || date > new Date() || date > maxDate
				? (formErrors.birth_date = 'Enter a valid date.')
				: delete formErrors.birth_date;
			break;

		case 'email':
			validate = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(value);
			!validate
				? (formErrors.email = 'Enter a valid email address.')
				: delete formErrors.email;
			break;

		case 'phone_one':
			validate = /^([0-9()+ -]{10,})$/.test(value);
			!validate
				? (formErrors.phone_one = `Enter a valid phone number.`)
				: delete formErrors.phone_one;
			break;

		case 'country':
			validate = value !== '' && value !== 'default';
			!validate
				? (formErrors.country = 'Select country.')
				: delete formErrors.country;
			break;

		case 'product_id':
			validate = value !== null && value !== undefined && value !== '';
			!validate
				? (formErrors.product_id = 'You must select a health plan.')
				: delete formErrors.product_id;
			break;
		case 'state':
			validate =
				/^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ,.-]{2,})$/.test(value) && value !== 'default';
			!validate
				? (formErrors.state = 'Select province')
				: delete formErrors.state;
			break;

		case 'municipality':
			validate =
				/^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ,.-]{2,})$/.test(value) && value !== 'default';
			!validate
				? (formErrors.municipality = 'Select city')
				: delete formErrors.municipality;
			break;
		case 'city':
			validate =
				/^[a-zA-ZñÑáéíóúÁÉÍÓÚüÜ0-9 _.,\-!()+=“”„@"$#%*]{2,}$/.test(value) &&
				value !== 'default';

			!validate
				? (formErrors.city = titular.include_titular
						? 'Select barangay.'
						: 'Select city')
				: delete formErrors.city;
			break;

		case 'address':
			validate = /^([a-zA-Z0-9ñÑáéíóúÁÉÍÓÚüÜ #,.-]{5,})$/.test(value);
			!validate
				? (formErrors.address = 'You must enter a valid address.')
				: delete formErrors.address;
			break;

		case 'code':
			validate = /^[0-9]*$/.test(value);
			!validate
				? (formErrors.code = 'Invalid code, must be numeric.')
				: delete formErrors.code;
			break;

		case 'phil_health':
			validate =
				titular?.phil_health !== null || titular?.phil_health !== undefined;

			!validate
				? (formErrors.phil_health = 'Enter a valid PhilHealth.')
				: delete formErrors.phil_health;
			break;

		case 'phil_health_number':
			validate = titular?.phil_health
				? value !== '' && alphanumeric(value) && value.length === 12
				: true;

			!validate
				? (formErrors.phil_health_number = 'Enter a valid PhilHealth number.')
				: delete formErrors.phil_health_number;
			break;

		case 'second_nationality':
			validate = !titular?.phil_health
				? /^[a-zA-Z\- \/_?':.,\s]+$/.test(value) && value !== 'default'
				: true;
			!validate
				? (formErrors.second_nationality = 'Select second type of ID number')
				: delete formErrors.second_nationality;
			break;

		case 'second_dni':
			const isRequired = titular?.phil_health === false ? true : false;

			if (titular?.second_nationality === "Driver's License")
				validate = /^([a-zA-Z0-9]{11})$/.test(value);
			else if (titular?.second_nationality === 'Pag-Ibig')
				validate = /^([a-zA-Z0-9]{12})$/.test(value);
			else if (titular?.second_nationality === 'Passport')
				validate = /^([a-zA-Z0-9]{9,})$/.test(value);
			else if (titular?.second_nationality === 'SSS')
				validate = /^([a-zA-Z0-9]{10})$/.test(value);
			else if (titular?.second_nationality === 'TIN')
				validate = /^(\d{9})$/.test(value);
			else if (titular?.second_nationality === 'UMID') validate = !!value;
			else if (titular?.second_nationality === 'PRC ID')
				validate = /^(\d{7})$/.test(value);
			else if (titular?.second_nationality === "Voter's ID")
				validate = /^([a-zA-Z0-9]{22})$/.test(value);
			else if (titular?.second_nationality === 'OWWA e-card')
				validate = /^(\d{11})$/.test(value);
			else if (titular?.second_nationality === 'Postal ID')
				validate = /^(\d{12})$/.test(value);
			else if (titular?.second_nationality === 'Senior Citizen ID')
				validate = /^(\d{4,5})$/.test(value);
			else validate = /^([a-zA-Z0-9]{3,20})$/.test(value);
			!validate && isRequired
				? (formErrors.second_dni = 'Enter a valid second ID number.')
				: delete formErrors.second_dni;
			break;

		default:
			break;
	}

	titular.formErrors = formErrors;
	saveState('titular', titular);
};

/**
 * Step 1 - Register Titular
 * @param {objet} titular
 * @param {function redux} saveState
 * @param {string} field
 * @param {any} value
 */
const validateFieldHeadLineRequired = (titular, saveState, field, value) => {
	let formErrors = titular.formErrors;

	let validate = false;
	switch (field) {
		case 'first_name':
			validate = /^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ]{3,})$/.test(value);
			!validate
				? (formErrors.first_name = 'Enter a valid name.')
				: delete formErrors.first_name;
			break;

		case 'last_name':
			validate = /^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ]{3,})$/.test(value);
			!validate
				? (formErrors.last_name = 'Enter a valid last name.')
				: delete formErrors.last_name;
			break;

		case 'nationality':
			validate = /^[a-zA-Z\- \/_?':.,\s]+$/.test(value) && value !== 'default';
			!validate
				? (formErrors.nationality = 'Select type of ID number')
				: delete formErrors.nationality;
			break;

		case 'dni':
			if (titular?.nationality === "Driver's License")
				validate = /^([a-zA-Z0-9]{11})$/.test(value);
			else if (titular?.nationality === 'Pag-Ibig')
				validate = /^([a-zA-Z0-9]{12})$/.test(value);
			else if (titular?.nationality === 'Passport')
				validate = /^([a-zA-Z0-9]{9,})$/.test(value);
			else if (titular?.nationality === 'SSS')
				validate = /^([a-zA-Z0-9]{10})$/.test(value);
			else if (titular?.nationality === 'TIN')
				validate = /^(\d{9})$/.test(value);
			else if (titular?.nationality === 'UMID') validate = !!value;
			else if (titular?.nationality === 'PRC ID')
				validate = /^(\d{7})$/.test(value);
			else if (titular?.nationality === "Voter's ID")
				validate = /^([a-zA-Z0-9]{22})$/.test(value);
			else if (titular?.nationality === 'OWWA e-card')
				validate = /^(\d{11})$/.test(value);
			else if (titular?.nationality === 'Postal ID')
				validate = /^(\d{12})$/.test(value);
			else if (titular?.nationality === 'Senior Citizen ID')
				validate = /^(\d{4,5})$/.test(value);
			else validate = /^([a-zA-Z0-9]{3,20})$/.test(value);
			!validate
				? (formErrors.dni = 'Enter a valid ID number.')
				: delete formErrors.dni;
			break;

		case 'doc_dni':
			const type = value && value.type ? value.type : null;
			!(
				type === 'image/jpeg' ||
				type === 'image/jpg' ||
				type === 'image/png' ||
				type === 'application/pdf'
			) || type === null
				? (formErrors.doc_dni = 'You must attach a valid identity document.')
				: delete formErrors.doc_dni;
			break;

		case 'sex':
			validate = /^([FM]{1})$/.test(value) && value !== 'default';
			!validate ? (formErrors.sex = 'Select gender.') : delete formErrors.sex;
			break;

		case 'birth_date':
			const maxDate = new Date(
				new Date().setFullYear(new Date().getFullYear() - 18)
			);
			let date = value ? new Date(value) : null;
			let isDate = isValidDate(date);
			!isDate || date > new Date() || date > maxDate
				? (formErrors.birth_date = 'Enter a date.')
				: delete formErrors.birth_date;
			break;

		case 'email':
			validate = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(value);
			!validate
				? (formErrors.email = 'Enter a valid email address.')
				: delete formErrors.email;
			break;

		case 'phone_one':
			validate = /^([0-9()+ -]{10,})$/.test(value);
			!validate
				? (formErrors.phone_one = `Enter a valid phone number.`)
				: delete formErrors.phone_one;
			break;

		case 'country':
			validate = value !== '' && value !== 'default';
			!validate
				? (formErrors.country = 'Select country.')
				: delete formErrors.country;
			break;

		case 'city':
			validate =
				/^[a-zA-ZñÑáéíóúÁÉÍÓÚüÜ0-9 _.,\-!()+=“”„@"$#%*]{2,}$/.test(value) &&
				value !== 'default';

			!validate
				? (formErrors.city = 'You must enter a valid city.')
				: delete formErrors.city;
			break;

		case 'address':
			validate = /^([a-zA-Z0-9ñÑáéíóúÁÉÍÓÚüÜ #,.-]{5,})$/.test(value);
			!validate
				? (formErrors.address = 'You must enter a valid address.')
				: delete formErrors.address;
			break;

		default:
			break;
	}

	titular.formErrors = formErrors;
	saveState('titular', titular);
};

const validateTitularForm = (titular, saveState, field, value) => {
	let formErrors = titular.formErrors;
	let validate = false;
	switch (field) {
		case 'first_name':
			validate = /^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ]{3,})$/.test(value);
			!validate
				? (formErrors.first_name = 'Enter a valid name.')
				: delete formErrors.first_name;
			break;

		case 'middle_name':
			validate = /^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ]{3,})$/.test(value);
			!validate
				? (formErrors.middle_name = 'Enter a valid middle name.')
				: delete formErrors.middle_name;
			break;

		case 'last_name':
			validate = /^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ]{3,})$/.test(value);
			!validate
				? (formErrors.last_name = 'Enter a valid last name.')
				: delete formErrors.last_name;
			break;

		case 'email':
			validate = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(value);
			!validate
				? (formErrors.email = 'Enter a valid email address.')
				: delete formErrors.email;
			break;

		case 'phone_one':
			validate = /^([0-9()+ -]{10,})$/.test(value);
			!validate
				? (formErrors.phone_one = `Enter a valid phone number.`)
				: delete formErrors.phone_one;
			break;

		case 'agree_policy_privacy':
			validate = value === true ? true : false;
			!validate
				? (formErrors.agree_policy_privacy =
						'Debe aceptar las términos y condiciones')
				: delete formErrors.agree_policy_privacy;
			break;

		case 'password':
			validate =
				/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@_#\$%\^&\*\.\+\-])(?=.{8,20})/.test(
					value
				);

			!validate
				? (formErrors.password =
						'Please choose a password with at least 8 characters that includes at least 1 uppercase character, 1 number, and 1 special character "!@#$%^&*.+.')
				: delete formErrors.password;
			if (titular?.password && !formErrors.password) {
				value === titular?.password_confirm
					? delete formErrors.password_confirm
					: (formErrors.password_confirm =
							'Password did not match. Please try again.');
			}
			break;

		case 'password_confirm':
			validate = value === titular?.password;
			!validate
				? (formErrors.password_confirm =
						'Password did not match. Please try again.')
				: delete formErrors.password_confirm;
			break;

		default:
			break;
	}
	titular.formErrors = formErrors;
	saveState('titular', titular);
};

const validateFieldBeneficiaries = (
	beneficiaries,
	saveState,
	field,
	value,
	index
) => {
	let formErrors = beneficiaries[index].formErrors || {};
	let validate = false;
	switch (field) {
		case 'kinship':
			validate =
				/^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ]{1,})$/.test(value) && value !== 'default';
			!validate
				? (formErrors.kinship = 'Select relationship option')
				: delete formErrors.kinship;
			break;

		case 'other_kinship':
			validate =
				beneficiaries[index].kinship === 'Other'
					? /^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ]{3,})$/.test(value)
					: value === '';
			!validate
				? (formErrors.other_kinship = 'You must specify the relationship.')
				: delete formErrors.other_kinship;
			break;

		case 'first_name':
			validate = /^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ]{3,})$/.test(value);
			!validate
				? (formErrors.first_name = 'Enter a valid name.')
				: delete formErrors.first_name;
			break;

		case 'last_name':
			validate = /^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ]{3,})$/.test(value);
			!validate
				? (formErrors.last_name = 'Enter a valid last name.')
				: delete formErrors.last_name;
			break;

		case 'nationality':
			validate = /^[a-zA-Z\- \/_?':.,\s]+$/.test(value) && value !== 'default';
			!validate
				? (formErrors.nationality = 'Select type of ID number')
				: delete formErrors.nationality;
			break;

		case 'civil_status':
			validate =
				/^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ]{3,})$/.test(value) && value !== 'default';
			!validate
				? (formErrors.civil_status = 'Select civil status.')
				: delete formErrors.civil_status;
			break;

		case 'dni':
			if (beneficiaries[index]?.nationality === "Driver's License")
				validate = /^([a-zA-Z0-9]{11})$/.test(value);
			else if (beneficiaries[index]?.nationality === 'Pag-Ibig')
				validate = /^([a-zA-Z0-9]{12})$/.test(value);
			else if (beneficiaries[index]?.nationality === 'Passport')
				validate = /^([a-zA-Z0-9]{9,})$/.test(value);
			else if (beneficiaries[index]?.nationality === 'SSS')
				validate = /^([a-zA-Z0-9]{10})$/.test(value);
			else if (beneficiaries[index]?.nationality === 'TIN')
				validate = /^(\d{9})$/.test(value);
			else if (beneficiaries[index]?.nationality === 'UMID') validate = !!value;
			else if (beneficiaries[index]?.nationality === 'PRC ID')
				validate = /^(\d{7})$/.test(value);
			else if (beneficiaries[index]?.nationality === "Voter's ID")
				validate = /^([a-zA-Z0-9]{22})$/.test(value);
			else if (beneficiaries[index]?.nationality === 'OWWA e-card')
				validate = /^(\d{11})$/.test(value);
			else if (beneficiaries[index]?.nationality === 'Postal ID')
				validate = /^(\d{12})$/.test(value);
			else if (beneficiaries[index]?.nationality === 'Senior Citizen ID')
				validate = /^(\d{4,5})$/.test(value);
			else validate = /^([a-zA-Z0-9]{3,20})$/.test(value);
			!validate
				? (formErrors.dni = 'Enter a valid ID number.')
				: delete formErrors.dni;
			break;

		case 'sex':
			validate = /^([FM]{1})$/.test(value) && value !== 'default';
			!validate ? (formErrors.sex = 'Select gender.') : delete formErrors.sex;
			break;

		case 'birth_date':
			let date = value ? new Date(value) : null;
			let isDate = isValidDate(date);
			!isDate || date > new Date()
				? (formErrors.birth_date = 'Enter a date.')
				: delete formErrors.birth_date;
			break;

		case 'email':
			validate = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(value);
			!validate
				? (formErrors.email = 'Enter a valid email address.')
				: delete formErrors.email;
			break;

		case 'phone_one':
			validate = /^([0-9()+ -]{10,})$/.test(value);
			!validate
				? (formErrors.phone_one = `Enter a valid phone number.`)
				: delete formErrors.phone_one;
			break;

		case 'product_id':
			validate = value !== null && value !== undefined && value !== '';
			!validate
				? (formErrors.product_id = 'You must select a health plan.')
				: delete formErrors.product_id;
			break;
		case 'country':
			validate = value !== '' && value !== 'default';
			!validate
				? (formErrors.country = 'Select country.')
				: delete formErrors.country;
			break;

		case 'state':
			validate =
				/^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ,.-]{2,})$/.test(value) && value !== 'default';
			!validate
				? (formErrors.state = 'Select province')
				: delete formErrors.state;
			break;

		case 'municipality':
			validate =
				/^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ,.-]{2,})$/.test(value) && value !== 'default';
			!validate
				? (formErrors.municipality = 'Select city')
				: delete formErrors.municipality;
			break;

		case 'city':
			validate =
				/^[a-zA-ZñÑáéíóúÁÉÍÓÚüÜ0-9 _.,\-!()+=“”„@"$#%*]{2,}$/.test(value) &&
				value !== 'default';

			!validate
				? (formErrors.city = 'Select barangay.')
				: delete formErrors.city;
			break;

		case 'address':
			validate = /^([a-zA-Z0-9ñÑáéíóúÁÉÍÓÚüÜ #,.-]{5,})$/.test(value);
			!validate
				? (formErrors.address = 'You must enter a valid address.')
				: delete formErrors.address;
			break;

		case 'phil_health':
			validate =
				beneficiaries[index]?.phil_health !== null ||
				beneficiaries[index]?.phil_health !== undefined;

			!validate
				? (formErrors.phil_health = 'Enter a valid PhilHealth.')
				: delete formErrors.phil_health;
			break;

		case 'phil_health_number':
			validate = beneficiaries[index]?.phil_health
				? value !== '' && alphanumeric(value) && value.length === 12
				: true;
			!validate
				? (formErrors.phil_health_number = 'Enter a valid PhilHealth number.')
				: delete formErrors.phil_health_number;
			break;

		case 'second_nationality':
			validate = !beneficiaries[index]?.phil_health
				? /^[a-zA-Z\- \/_?':.,\s]+$/.test(value) && value !== 'default'
				: true;
			!validate
				? (formErrors.second_nationality = 'Select second type of ID number')
				: delete formErrors.second_nationality;
			break;

		case 'second_dni':
			const isRequired =
				beneficiaries[index]?.phil_health === false ? true : false;

			if (beneficiaries[index]?.second_nationality === "Driver's License")
				validate = /^([a-zA-Z0-9]{11})$/.test(value);
			else if (beneficiaries[index]?.second_nationality === 'Pag-Ibig')
				validate = /^([a-zA-Z0-9]{12})$/.test(value);
			else if (beneficiaries[index]?.second_nationality === 'Passport')
				validate = /^([a-zA-Z0-9]{9,})$/.test(value);
			else if (beneficiaries[index]?.second_nationality === 'SSS')
				validate = /^([a-zA-Z0-9]{10})$/.test(value);
			else if (beneficiaries[index]?.second_nationality === 'TIN')
				validate = /^(\d{9})$/.test(value);
			else if (beneficiaries[index]?.second_nationality === 'UMID')
				validate = !!value;
			else if (beneficiaries[index]?.second_nationality === 'PRC ID')
				validate = /^(\d{7})$/.test(value);
			else if (beneficiaries[index]?.second_nationality === "Voter's ID")
				validate = /^([a-zA-Z0-9]{22})$/.test(value);
			else if (beneficiaries[index]?.second_nationality === 'OWWA e-card')
				validate = /^(\d{11})$/.test(value);
			else if (beneficiaries[index]?.second_nationality === 'Postal ID')
				validate = /^(\d{12})$/.test(value);
			else if (beneficiaries[index]?.second_nationality === 'Senior Citizen ID')
				validate = /^(\d{4,5})$/.test(value);
			else validate = /^([a-zA-Z0-9]{3,20})$/.test(value);
			!validate && isRequired
				? (formErrors.second_dni = 'Enter a valid second ID number.')
				: delete formErrors.second_dni;
			break;

		default:
			break;
	}
	beneficiaries[index].formErrors = formErrors;
	saveState('beneficiaries', beneficiaries);
};

const validatePaymentForm = (payment, saveState, field, value) => {
	let formErrors = payment.formErrors;
	let validate = false;
	switch (field) {
		case 'first_name':
			validate = /^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ,.-]{3,})$/.test(value);
			!validate
				? (formErrors.first_name = 'Enter a valid name.')
				: delete formErrors.first_name;
			break;

		case 'last_name':
			validate = /^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ,.-]{3,})$/.test(value);
			!validate
				? (formErrors.last_name = 'Enter a valid last name.')
				: delete formErrors.last_name;
			break;

		case 'email':
			validate = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(value);
			!validate
				? (formErrors.email = 'Enter a valid email address.')
				: delete formErrors.email;
			break;

		case 'phone_one':
			validate = /^([0-9()+ -]{10,})$/.test(value);
			!validate
				? (formErrors.phone_one = `Enter a valid phone number.`)
				: delete formErrors.phone_one;
			break;

		case 'zip_zone':
			validate = /^([0-9]{3,})$/.test(value);
			!validate
				? (formErrors.zip_zone = 'Enter a valid zip code')
				: delete formErrors.zip_zone;
			break;

		case 'country':
			validate =
				/^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ,.-]{2,})$/.test(value) && value !== 'default';
			!validate
				? (formErrors.country = 'Select country.')
				: delete formErrors.country;
			break;

		case 'state':
			validate = /^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ,.-]{2,})$/.test(value);
			!validate
				? (formErrors.state = 'You must enter a valid state.')
				: delete formErrors.state;
			break;

		case 'city':
			validate = /^[a-zA-ZñÑáéíóúÁÉÍÓÚüÜ0-9 _.,\-!()+=“”„@"$#%*]{2,}$/.test(
				value
			);
			!validate
				? (formErrors.city = 'You must enter a valid city.')
				: delete formErrors.city;
			break;

		case 'acceptTerms':
			validate = value === true;
			!validate
				? (formErrors.acceptTerms =
						'Please indicate that you have read and agree to the Plan Terms and Conditions')
				: delete formErrors.acceptTerms;
			break;

		case 'oath_statement':
			validate = value === true;
			!validate
				? (formErrors.oath_statement =
						'Please certify that all of the information provided is accurate')
				: delete formErrors.oath_statement;
			break;

		case 'payment_interval':
			validate =
				/^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ,.-]{1,})$/.test(value) && value !== 'default';
			!validate
				? (formErrors.payment_interval = 'Select a billing cycle')
				: delete formErrors.payment_interval;
			break;

		case 'payment_method':
			validate = /^([ECZSBTP]{1})$/.test(value) && value !== 'default';
			!validate
				? (formErrors.payment_method = 'Select a payment method')
				: delete formErrors.payment_method;
			break;

		case 'payment_voucher':
			const type = value && value.type ? value.type : null;
			payment.payment_method === 'Z' ||
			payment.payment_method === 'T' ||
			payment.payment_method === 'P'
				? !(
						type === 'image/jpeg' ||
						type === 'image/jpg' ||
						type === 'image/png' ||
						type === 'application/pdf'
				  )
					? (formErrors.payment_voucher =
							'Upload a valid image format (.jpeg, .jpg, .png, .pdf)')
					: delete formErrors.payment_voucher
				: delete formErrors.payment_voucher;
			break;

		case 'reference_number':
			validate =
				payment.payment_method === 'T' ||
				payment.payment_method === 'Z' ||
				payment.payment_method === 'P'
					? /^([a-zA-Z0-9]{3,})$/.test(value)
					: true;
			if (!validate) {
				formErrors.reference_number = 'You must enter a valid receipt number.';
			} else {
				delete formErrors.reference_number;
			}
			break;

		case 'name':
			validate =
				payment.payment_method === 'S'
					? /^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ,.-]{3,})$/.test(value)
					: true;
			!validate
				? (formErrors.name =
						'Debe ingresar un nombre de propietario de la tarjeta válido.')
				: delete formErrors.name;
			break;

		case 'number':
			validate =
				payment.payment_method === 'S' ? /^([0-9]{14,16})$/.test(value) : true;
			!validate
				? (formErrors.number = 'Enter a valid card number')
				: delete formErrors.number;
			break;

		case 'exp':
			validate =
				payment.payment_method === 'S' ? /^([0-9//]{5})$/.test(value) : true;
			!validate
				? (formErrors.exp = 'Enter a valid experiation date')
				: delete formErrors.exp;
			break;

		case 'cvc':
			validate =
				payment.payment_method === 'S' ? /^([0-9]{3,4})$/.test(value) : true;
			!validate
				? (formErrors.cvc = 'Enter a valid CVC')
				: delete formErrors.cvc;
			break;
		case 'dni':
			validate = /^([0-9a-zA-Z]{3,20})$/.test(value);
			!validate
				? (formErrors.dni = 'Enter a valid ID number.')
				: delete formErrors.dni;
			break;

		default:
			break;
	}
	payment.formErrors = formErrors;
	saveState({ payment });
	return payment;
};

const validateFieldBeneficiariesRequired = (
	beneficiaries,
	saveState,
	field,
	value,
	index
) => {
	let formErrors = beneficiaries[index].formErrors;
	let validate = false;

	switch (field) {
		case 'first_name':
			validate = /^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ]{3,})$/.test(value);
			!validate
				? (formErrors.first_name = 'Enter a valid name.')
				: delete formErrors.first_name;
			break;

		case 'last_name':
			validate = /^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ]{3,})$/.test(value);
			!validate
				? (formErrors.last_name = 'Enter a valid last name.')
				: delete formErrors.last_name;
			break;

		case 'nationality':
			validate = /^[a-zA-Z\- \/_?':.,\s]+$/.test(value) && value !== 'default';
			!validate
				? (formErrors.nationality = 'Select type of ID number')
				: delete formErrors.nationality;
			break;

		case 'sex':
			validate = /^([FM]{1})$/.test(value) && value !== 'default';
			!validate ? (formErrors.sex = 'Select gender.') : delete formErrors.sex;
			break;

		case 'civil_status':
			validate =
				/^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ]{3,})$/.test(value) && value !== 'default';
			!validate
				? (formErrors.civil_status = 'Select civil status.')
				: delete formErrors.civil_status;
			break;

		case 'phil_health':
			validate =
				beneficiaries[index]?.phil_health !== null ||
				beneficiaries[index]?.phil_health !== undefined;

			!validate
				? (formErrors.phil_health = 'Enter a valid PhilHealth.')
				: delete formErrors.phil_health;
			break;

		case 'phil_health_number':
			validate = beneficiaries[index]
				? value !== '' && alphanumeric(value) && value.length === 12
				: true;

			!validate
				? (formErrors.phil_health_number = 'Enter a valid PhilHealth number.')
				: delete formErrors.phil_health_number;
			break;

		case 'kinship':
			validate =
				/^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ]{1,})$/.test(value) && value !== 'default';
			!validate
				? (formErrors.kinship = 'Select relationship option')
				: delete formErrors.kinship;
			break;

		case 'other_kinship':
			validate =
				beneficiaries[index].kinship === 'Other'
					? /^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ]{3,})$/.test(value)
					: value === '';
			!validate
				? (formErrors.other_kinship = 'You must specify the relationship.')
				: delete formErrors.other_kinship;
			break;

		case 'dni':
			if (beneficiaries[index]?.nationality === "Driver's License")
				validate = /^([a-zA-Z0-9]{11})$/.test(value);
			else if (beneficiaries[index]?.nationality === 'Pag-Ibig')
				validate = /^([a-zA-Z0-9]{12})$/.test(value);
			else if (beneficiaries[index]?.nationality === 'Passport')
				validate = /^([a-zA-Z0-9]{9,})$/.test(value);
			else if (beneficiaries[index]?.nationality === 'SSS')
				validate = /^([a-zA-Z0-9]{10})$/.test(value);
			else if (beneficiaries[index]?.nationality === 'TIN')
				validate = /^(\d{9})$/.test(value);
			else if (beneficiaries[index]?.nationality === 'UMID') validate = !!value;
			else if (beneficiaries[index]?.nationality === 'PRC ID')
				validate = /^(\d{7})$/.test(value);
			else if (beneficiaries[index]?.nationality === "Voter's ID")
				validate = /^([a-zA-Z0-9]{22})$/.test(value);
			else if (beneficiaries[index]?.nationality === 'OWWA e-card')
				validate = /^(\d{11})$/.test(value);
			else if (beneficiaries[index]?.nationality === 'Postal ID')
				validate = /^(\d{12})$/.test(value);
			else if (beneficiaries[index]?.nationality === 'Senior Citizen ID')
				validate = /^(\d{4,5})$/.test(value);
			else validate = /^([a-zA-Z0-9]{3,20})$/.test(value);

			!validate
				? (formErrors.dni = 'Enter a valid ID number.')
				: delete formErrors.dni;
			break;

		case 'birth_date':
			let date = value ? new Date(value) : null;
			let isDate = isValidDate(date);
			!isDate || date > new Date()
				? (formErrors.birth_date = 'Enter a date.')
				: delete formErrors.birth_date;
			break;
		default:
			break;
	}
	beneficiaries[index].formErrors = formErrors;
	saveState('beneficiaries', beneficiaries);
};

const validateFieldContact = (contact, saveState, field, value) => {
	let formErrors = contact.formErrors;
	let validate = false;
	switch (field) {
		case 'name':
			validate = /^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ]{3,})$/.test(value);
			!validate ? (formErrors.name = 'Invalid names.') : delete formErrors.name;
			break;

		case 'dni':
			if (titular?.nationality === "Driver's License")
				validate = /^([a-zA-Z0-9]{11})$/.test(value);
			else if (titular?.nationality === 'Pag-Ibig')
				validate = /^([a-zA-Z0-9]{12})$/.test(value);
			else if (titular?.nationality === 'Passport')
				validate = /^([a-zA-Z0-9]{9,})$/.test(value);
			else if (titular?.nationality === 'SSS')
				validate = /^([a-zA-Z0-9]{10})$/.test(value);
			else if (titular?.nationality === 'TIN')
				validate = /^(\d{9})$/.test(value);
			else if (titular?.nationality === 'UMID') validate = !!value;
			else if (titular?.nationality === 'PRC ID')
				validate = /^(\d{7})$/.test(value);
			else if (titular?.nationality === "Voter's ID")
				validate = /^([a-zA-Z0-9]{22})$/.test(value);
			else if (titular?.nationality === 'OWWA e-card')
				validate = /^(\d{11})$/.test(value);
			else if (titular?.nationality === 'Postal ID')
				validate = /^(\d{12})$/.test(value);
			else if (titular?.nationality === 'Senior Citizen ID')
				validate = /^(\d{4,5})$/.test(value);
			else validate = /^([a-zA-Z0-9]{3,20})$/.test(value);
			!validate
				? (formErrors.dni = 'You must enter a valid ID type.')
				: delete formErrors.dni;
			break;

		case 'phone':
			validate = /^([0-9()+ -]{10,})$/.test(value);
			!validate
				? (formErrors.phone = `Invalid phone. Example: (${DEFAULT_CODE_TLF}) 416 765-432`)
				: delete formErrors.phone;
			break;

		case 'email':
			validate = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(value);
			!validate
				? (formErrors.email = 'Invalid email address.')
				: delete formErrors.email;
			break;

		case 'message':
			validate = /^([a-zA-Z0-9ñÑáéíóúÁÉÍÓÚüÜ ,.-¿?']{15,})$/.test(value);
			!validate
				? (formErrors.message = 'Must enter a valid message.')
				: delete formErrors.message;
			break;

		default:
			break;
	}
	contact.formErrors = formErrors;
	saveState({ contact });
};

const validFieldInitAffiliation = (formErrors, field, value) => {
	let validate = false;

	switch (field) {
		case 'dni':
			if (titular?.nationality === "Driver's License")
				validate = /^([a-zA-Z0-9]{11})$/.test(value);
			else if (titular?.nationality === 'Pag-Ibig')
				validate = /^([a-zA-Z0-9]{12})$/.test(value);
			else if (titular?.nationality === 'Passport')
				validate = /^([a-zA-Z0-9]{9,})$/.test(value);
			else if (titular?.nationality === 'SSS')
				validate = /^([a-zA-Z0-9]{10})$/.test(value);
			else if (titular?.nationality === 'TIN')
				validate = /^(\d{9})$/.test(value);
			else if (titular?.nationality === 'UMID') validate = !!value;
			else if (titular?.nationality === 'PRC ID')
				validate = /^(\d{7})$/.test(value);
			else if (titular?.nationality === "Voter's ID")
				validate = /^([a-zA-Z0-9]{22})$/.test(value);
			else if (titular?.nationality === 'OWWA e-card')
				validate = /^(\d{11})$/.test(value);
			else if (titular?.nationality === 'Postal ID')
				validate = /^(\d{12})$/.test(value);
			else if (titular?.nationality === 'Senior Citizen ID')
				validate = /^(\d{4,5})$/.test(value);
			else validate = /^([a-zA-Z0-9]{3,20})$/.test(value);
			!validate
				? (formErrors.dni = 'You must enter a valid ID type.')
				: delete formErrors.dni;
			break;

		case 'email':
			validate = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(value);
			!validate
				? (formErrors.email = 'Enter a valid email address.')
				: delete formErrors.email;
			break;

		default:
			break;
	}
	return formErrors;
};

const isValidDate = (date) => {
	return date instanceof Date && !isNaN(date);
};

const validateFieldsProduct = (formErrors, field, value) => {
	let validate = false;

	switch (field) {
		case 'termsFiles':
			const type = value && value.type ? value.type : null;
			type && type !== 'application/pdf'
				? (formErrors.termsFiles = 'Archivo inválido, solo archivos .pdf')
				: delete formErrors.termsFiles;
			break;
		case 'logo':
			const types = ['image/jpeg', 'image/jpg', 'image/png'];
			const typeLogo = value && value.type ? value.type : null;
			typeLogo && !types.includes(typeLogo)
				? (formErrors.logo = 'Archivo inválido, solo archivos .png .jpg .jpeg')
				: delete formErrors.logo;
			break;
		case 'name':
			validate = /^([a-zA-Z0-9ñÑáéíóúÁÉÍÓÚüÜ,.:;!@#$?¿%^&*()-_ ]{1,})$/.test(
				value
			);
			!validate
				? (formErrors.name = 'Enter a valid name.')
				: delete formErrors.name;
			break;
		case 'survey':
			validate = value !== 'default';
			!validate
				? (formErrors.survey = 'Enter a valid name.')
				: delete formErrors.survey;
			break;
		case 'not':
			validate = /^([a-zA-Z0-9ñÑáéíóúÁÉÍÓÚüÜ,.:;!@#$?¿%^&*()-_  ]{2,})$/.test(
				value
			);
			!validate
				? (formErrors.no = 'Respuesta invalida.')
				: delete formErrors.no;
			break;
		case 'yes':
			validate = /^([a-zA-Z0-9ñÑáéíóúÁÉÍÓÚüÜ,.:;!@#$?¿%^&*()-_  ]{2,})$/.test(
				value
			);
			!validate
				? (formErrors.yes = 'Respuesta Invalida.')
				: delete formErrors.yes;
			break;
		case 'sex':
			validate = /^([a-zA-Z0-9ñÑáéíóúÁÉÍÓÚüÜ,.:;!@#$?¿%^&*()-_  ]{1,})$/.test(
				value
			);
			!validate ? (formErrors.sex = 'Select gender.') : delete formErrors.sex;
			break;
		case 'help':
			validate = /^([a-zA-Z0-9ñÑáéíóúÁÉÍÓÚüÜ,.:;!@#$?¿%^&*()-_  ]{3,})$/.test(
				value
			);
			!validate
				? (formErrors.help = 'Ayuda inválida.')
				: delete formErrors.help;
			break;
		case 'value':
			validate = /^([a-zA-Z0-9ñÑáéíóúÁÉÍÓÚüÜ,.:;!@#$?¿%^&*()-_  ]{3,})$/.test(
				value
			);
			!validate
				? (formErrors.value = 'Valor inválido.')
				: delete formErrors.value;
			break;
		case 'shortName':
			validate = /^([A-ZÑÁÉÍÓÚüÜ,.:;!@#$?¿%^&*()-_ ]{1,})$/.test(value);
			!validate
				? (formErrors.shortName =
						'Abreviación inválida, solo letras mayúsculas.')
				: delete formErrors.shortName;
			break;
		case 'countryCode':
			validate = /^([A-ZÑÁÉÍÓÚüÜ ]{1,})$/.test(value);
			!validate
				? (formErrors.countryCode = 'Codigo Invalido, solo letras mayúsculas.')
				: delete formErrors.countryCode;
			break;
		case 'recurrent_interval_base':
			validate = /^([a-zA-Z0-9ñÑáéíóúÁÉÍÓÚüÜ,.:;!@#$?¿%^&*()-_  ]{1,})$/.test(
				value
			);
			!validate
				? (formErrors.recurrent_interval_base = 'Intervalo inválido.')
				: delete formErrors.recurrent_interval_base;
			break;
		case 'country':
			validate = /^([a-zA-Z0-9ñÑáéíóúÁÉÍÓÚüÜ,.:;!@#$?¿%^&*()-_  ]{3,})$/.test(
				value
			);
			!validate
				? (formErrors.country = 'Pais inválido.')
				: delete formErrors.country;
			break;
		case 'price_base':
			validate = /^([0-9]{1,})$/.test(value) && value > 0;
			!validate
				? (formErrors.price_base = 'Precio invalido.')
				: delete formErrors.price_base;
			break;
		default:
			break;
	}
	return { formErrors };
};

const validateOptionsProduct = (
	formErrors,
	formErrorsOptions,
	field,
	value,
	index
) => {
	let validate = false;

	switch (field) {
		case 'name':
			validate = /^([a-zA-Z0-9ñÑáéíóúÁÉÍÓÚüÜ,.:;!@#$?¿%^&*()-_  ]{1,})$/.test(
				value
			);
			if (!validate) {
				formErrorsOptions.name[index] = 'Descripcion invalida';
				formErrors.nameSpecial = 'Descripcion invalida';
			} else {
				formErrorsOptions.name && formErrorsOptions.name.splice(index, 1);
				delete formErrors.nameSpecial;
			}
			break;
		case 'value':
			validate = /^([a-zA-Z0-9ñÑáéíóúÁÉÍÓÚüÜ,.:;!@#$?¿%^&*()-_  ]{1,})$/.test(
				value
			);
			if (!validate) {
				formErrorsOptions.value[index] = 'Valor invalido';
				formErrors.valueoptions = 'Valor invalido';
			} else {
				formErrorsOptions.value && formErrorsOptions.value.splice(index, 1);
				delete formErrors.valueoptions;
			}
			break;

		default:
			break;
	}
	return { formErrors, formErrorsOptions };
};

const validateServicesProduct = (
	formErrors,
	formErrorsServices,
	field,
	value,
	index
) => {
	let validate = false;

	switch (field) {
		case 'name':
			validate = /^([a-zA-Z0-9ñÑáéíóúÁÉÍÓÚüÜ,.:;!@#$?¿%^&*()-_  ]{3,})$/.test(
				value
			);
			if (!validate) {
				formErrorsServices.name[index] = 'Descripcion invalida';
				formErrors.nameSpecial = 'Descripcion invalida';
			} else {
				formErrorsServices.name && formErrorsServices.name.splice(index, 1);
				delete formErrors.nameSpecial;
			}

			break;
		case 'value':
			validate = /^([a-zA-Z0-9ñÑáéíóúÁÉÍÓÚüÜ,.:;!@#$?¿%^&*()-_  ]{1,})$/.test(
				value
			);
			if (!validate) {
				formErrorsServices.value[index] = 'Valor invalido';
				formErrors.valueSpecial = 'Valor invalido';
			} else {
				formErrorsServices.value && formErrorsServices.value.splice(index, 1);
				delete formErrors.valueSpecial;
			}
			break;

		default:
			break;
	}
	return { formErrors, formErrorsServices };
};

const validateFieldsTariffs = (
	formErrors,
	formErrorsTariffs,
	field,
	value,
	index
) => {
	let validate = false;

	switch (field) {
		case 'sex':
			validate = /^([a-zA-Z0-9ñÑáéíóúÁÉÍÓÚüÜ,.:;!@#$?¿%^&*()-_  ]{1,})$/.test(
				value
			);
			if (!validate) {
				formErrorsTariffs.sex[index] = 'Select gender.';
				formErrors.sex = 'Select gender.';
			} else {
				formErrorsTariffs.sex && formErrorsTariffs.sex.splice(index, 1);
				delete formErrors.sex;
			}
			break;
		case 'kinship':
			validate = /^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ]{3,})$/.test(value);
			if (!validate) {
				formErrorsTariffs.kinship[index] = 'Parentesco inválido';
				formErrors.kinship = 'Parentesco inválido';
			} else {
				formErrorsTariffs.kinship && formErrorsTariffs.kinship.splice(index, 1);
				delete formErrors.kinship;
			}
			break;
		case 'min_age':
			validate = /^([0-9]{1,})$/.test(value) && value >= 0;
			if (!validate) {
				formErrorsTariffs.min_age[index] = 'Edad inválida';
				formErrors.min_age = 'Edad inválida';
			} else {
				formErrorsTariffs.min_age && formErrorsTariffs.min_age.splice(index, 1);
				delete formErrors.min_age;
			}
			break;
		case 'max_age':
			validate = /^([0-9]{1,})$/.test(value) && value >= 0;
			if (!validate) {
				formErrorsTariffs.max_age[index] = 'Edad inválida';
				formErrors.max_age = 'Edad inválida';
			} else {
				formErrorsTariffs.max_age && formErrorsTariffs.max_age.splice(index, 1);
				delete formErrors.max_age;
			}
			break;
		case 'price':
			validate = /^([0-9.,]{1,})$/.test(value) && value >= 0;
			if (!validate) {
				formErrorsTariffs.price[index] = 'Precio inválido';
				formErrors.price = 'Precio inválido';
			} else {
				formErrorsTariffs.price && formErrorsTariffs.price.splice(index, 1);
				delete formErrors.price;
			}
			break;
		default:
			break;
	}
	return { formErrors, formErrorsTariffs };
};

const validateFieldsAddress = (
	formErrors,
	formErrorsAddress,
	field,
	value,
	index
) => {
	let validate = false;

	switch (field) {
		case 'city':
			validate = /^[a-zA-ZñÑáéíóúÁÉÍÓÚüÜ0-9 _.,\-!()+=“”„@"$#%*]{2,}$/.test(
				value
			);

			if (!validate) {
				formErrorsAddress.city[index] = 'Select city';
				formErrors.city = 'Select city';
			} else {
				formErrorsAddress.city.splice(index, 1);
				delete formErrors.city;
			}
			break;
		case 'address':
			validate = /^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ0-9;:.,\-# ]{3,})$/.test(value);
			if (!validate) {
				formErrorsAddress.address[index] = 'Enter a valid home address';
				formErrors.address = 'Enter a valid home address';
			} else {
				delete formErrorsAddress.address.splice(index, 1);
				delete formErrors.address;
			}
			break;

		default:
			break;
	}
	return { formErrors, formErrorsAddress };
};

const validateFieldsPaymentForms = (
	formErrors,
	formErrorsPaymentForm,
	field,
	value,
	index
) => {
	let validate = false;

	switch (field) {
		case 'value':
			validate = /^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ]{1,})$/.test(value);
			if (!validate) {
				formErrorsPaymentForm.value[index] = 'Valor invalido';
				formErrors.value = 'Valor invalido';
			} else {
				formErrorsPaymentForm.value.splice(index, 1);
				delete formErrors.value;
			}
			break;
		case 'name':
			validate = /^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ]{1,})$/.test(value);
			if (!validate) {
				formErrorsPaymentForm.name[index] = 'Enter a valid name.';
				formErrors.name = 'Enter a valid name.';
			} else {
				delete formErrorsPaymentForm.name.splice(index, 1);
				delete formErrors.name;
			}
			break;
		case 'recharge':
			validate = /^([0-9.,]{1,})$/.test(value);
			if (!validate) {
				formErrorsPaymentForm.recharge[index] = 'Recargo invalido';
				formErrors.recharge = 'Recargo invalido';
			} else {
				delete formErrorsPaymentForm.recharge.splice(index, 1);
				delete formErrors.recharge;
			}
			break;
		default:
			break;
	}
	return { formErrors, formErrorsPaymentForm };
};

const validateFieldsBasicCoupons = (formErrors, field, value) => {
	let validate = false;

	switch (field) {
		case 'full_name':
			validate = /^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ]{3,})$/.test(value);
			!validate
				? (formErrors.full_name = 'Enter a valid name.')
				: delete formErrors.full_name;
			break;
		case 'id':
			validate = /^([0-9a-zA-ZñÑáéíóúÁÉÍÓÚüÜ_-]{1,})$/.test(value);
			if (!validate) {
				formErrors.id = 'Invalid value.';
			} else {
				delete formErrors.id;
			}
			break;
		case 'percent_off':
			validate = /^([0-9.,]{1,})$/.test(value) && value >= 0;
			if (!validate) {
				formErrors.percent_off = 'Porcentaje inválido';
			} else {
				delete formErrors.percent_off;
			}
			break;
		case 'duration':
			validate = ['forever', 'once', 'repeating'].includes(value._id);
			if (!validate) {
				formErrors.duration = 'Duración inválida';
			} else {
				delete formErrors.duration;
			}
			break;
		case 'duration_in_months':
			validate = /^([0-9.,]{1,})$/.test(value) && value >= 0;
			if (!validate) {
				formErrors.duration_in_months = 'Duración inválida';
			} else {
				delete formErrors.duration_in_months;
			}
			break;
		case 'max_redemptions':
			validate = /^([0-9.,]{1,})$/.test(value) && value >= 0;
			if (!validate) {
				formErrors.max_redemptions = 'Cantidad inválida';
			} else {
				delete formErrors.max_redemptions;
			}
			break;
		case 'redeem_by':
			const date = value ? new Date(value) : null;
			validate = isValidDate(date);
			if (!validate) {
				formErrors.redeem_by = 'Cantidad inválida';
			} else {
				delete formErrors.redeem_by;
			}
			break;
		case 'nickname':
			validate = /^([a-zA-Z0-9-]{3,})$/.test(value);
			!validate
				? (formErrors.nickname = 'Apodo inválido.')
				: delete formErrors.nickname;
			break;
		case 'commision':
			validate = /^([0-9.,]{1,})$/.test(value) && value >= 0;
			!validate
				? (formErrors.commision = 'Comisión inválida.')
				: delete formErrors.commision;
			break;
		default:
			break;
	}
	return { formErrors };
};

const validateFieldsCoupons = (
	formErrors,
	formErrorsCoupons,
	field,
	value,
	index
) => {
	let validate = false;

	switch (field) {
		case 'id':
			validate = /^([0-9a-zA-ZñÑáéíóúÁÉÍÓÚüÜ_-]{1,})$/.test(value);
			if (!validate) {
				formErrorsCoupons.id[index] = 'Valor invalido';
				formErrors.id = 'Valor invalido';
			} else {
				formErrorsCoupons.id.splice(index, 1);
				delete formErrors.id;
			}
			break;
		case 'percent_off':
			validate = /^([0-9.,]{1,})$/.test(value) && value >= 0;
			if (!validate) {
				formErrorsCoupons.percent_off[index] = 'Porcentaje inválido';
				formErrors.percent_off = 'Porcentaje inválido';
			} else {
				delete formErrorsCoupons.percent_off.splice(index, 1);
				delete formErrors.percent_off;
			}
			break;
		case 'duration':
			validate = ['forever', 'once', 'repeating'].includes(value._id);
			if (!validate) {
				formErrorsCoupons.duration[index] = 'Duración inválida';
				formErrors.duration = 'Duración inválida';
			} else {
				delete formErrorsCoupons.duration.splice(index, 1);
				delete formErrors.duration;
			}
			break;
		case 'duration_in_months':
			validate = /^([0-9.,]{1,})$/.test(value) && value >= 0;
			if (!validate) {
				formErrorsCoupons.duration_in_months[index] = 'Duración inválida';
				formErrors.duration_in_months = 'Duración inválida';
			} else {
				delete formErrorsCoupons.duration_in_months.splice(index, 1);
				delete formErrors.duration_in_months;
			}
			break;
		case 'max_redemptions':
			validate = /^([0-9.,]{1,})$/.test(value) && value >= 0;
			if (!validate) {
				formErrorsCoupons.max_redemptions[index] = 'Cantidad inválida';
				formErrors.max_redemptions = 'Cantidad inválida';
			} else {
				delete formErrorsCoupons.max_redemptions.splice(index, 1);
				delete formErrors.max_redemptions;
			}
			break;
		case 'redeem_by':
			const date = value ? new Date(value) : null;
			validate = isValidDate(date);
			if (!validate) {
				formErrorsCoupons.redeem_by[index] = 'Cantidad inválida';
				formErrors.redeem_by = 'Cantidad inválida';
			} else {
				delete formErrorsCoupons.redeem_by.splice(index, 1);
				delete formErrors.redeem_by;
			}
			break;

		default:
			break;
	}
	return { formErrors, formErrorsCoupons };
};

const validateFieldsVoluntary = (formErrors, field, value) => {
	let validate = false;

	const isValidDate = (date) => {
		return date instanceof Date && !isNaN(date);
	};

	switch (field) {
		case 'first_name':
			validate = /^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ]{3,})$/.test(value);
			!validate
				? (formErrors.first_name = 'Enter a valid name.')
				: delete formErrors.first_name;
			break;
		case 'last_name':
			validate = /^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ]{3,})$/.test(value);
			!validate
				? (formErrors.last_name = 'Enter a valid last name.')
				: delete formErrors.last_name;
			break;
		case 'document_type':
			validate = value !== '' && value !== 'default';
			!validate
				? (formErrors.document_type = 'Select ID type')
				: delete formErrors.document_type;
			break;
		case 'dni':
			if (titular?.nationality === "Driver's License")
				validate = /^([a-zA-Z0-9]{11})$/.test(value);
			else if (titular?.nationality === 'Pag-Ibig')
				validate = /^([a-zA-Z0-9]{12})$/.test(value);
			else if (titular?.nationality === 'Passport')
				validate = /^([a-zA-Z0-9]{9,})$/.test(value);
			else if (titular?.nationality === 'SSS')
				validate = /^([a-zA-Z0-9]{10})$/.test(value);
			else if (titular?.nationality === 'TIN')
				validate = /^(\d{9})$/.test(value);
			else if (titular?.nationality === 'UMID') validate = !!value;
			else if (titular?.nationality === 'PRC ID')
				validate = /^(\d{7})$/.test(value);
			else if (titular?.nationality === "Voter's ID")
				validate = /^([a-zA-Z0-9]{22})$/.test(value);
			else if (titular?.nationality === 'OWWA e-card')
				validate = /^(\d{11})$/.test(value);
			else if (titular?.nationality === 'Postal ID')
				validate = /^(\d{12})$/.test(value);
			else if (titular?.nationality === 'Senior Citizen ID')
				validate = /^(\d{4,5})$/.test(value);
			else validate = /^([a-zA-Z0-9]{3,20})$/.test(value);
			!validate
				? (formErrors.dni = 'Enter a valid ID number.')
				: delete formErrors.dni;
			break;
		case 'cell_phone':
			validate = /^([0-9()+ -]{10,})$/.test(value);
			!validate
				? (formErrors.cell_phone = `Enter a valid phone number.`)
				: delete formErrors.cell_phone;
			break;
		case 'email':
			validate = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(value);
			!validate
				? (formErrors.email = 'Enter a valid email address.')
				: delete formErrors.email;
			break;
		case 'birth_date':
			let date = value ? new Date(value) : null;
			let isDate = isValidDate(date);
			!isDate || date > new Date()
				? (formErrors.birth_date = 'Enter a date')
				: delete formErrors.birth_date;
			break;
		case 'country':
			validate = value !== '' && value !== 'default';
			!validate
				? (formErrors.country = 'Select country.')
				: delete formErrors.country;
			break;
		case 'city':
			validate =
				/^[a-zA-ZñÑáéíóúÁÉÍÓÚüÜ0-9 _.,\-!()+=“”„@"$#%*]{2,}$/.test(value) &&
				value !== 'default';
			!validate ? (formErrors.city = 'Select city') : delete formErrors.city;
			break;
		case 'specialty':
			validate =
				/^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ -]{3,})$/.test(value.name) &&
				value.name !== 'default';
			!validate
				? (formErrors.specialty = 'Debe seleccionar una especialidad.')
				: delete formErrors.specialty;
			break;
		case 'msds':
			validate = /^([a-zA-Z0-9ñÑáéíóúÁÉÍÓÚüÜ ]{3,})$/.test(value);
			!validate
				? (formErrors.msds =
						'M.S.D.S  Ministerio de Salud y Desarollo Social inválido.')
				: delete formErrors.msds;
			break;
		case 'medical_college':
			validate =
				/^([a-zA-Z0-9ñÑáéíóúÁÉÍÓÚüÜ ]{3,})$/.test(value) || value === '';
			!validate
				? (formErrors.medical_college =
						'Número de colegio de médicos inválido.')
				: delete formErrors.medical_college;
			break;
		case 'doc_dni':
			const typeDni = value && value.type ? value.type : null;
			!(
				typeDni === 'application/pdf' ||
				typeDni === 'image/jpeg' ||
				typeDni === 'image/jpg' ||
				typeDni === 'image/png'
			)
				? (formErrors.doc_dni =
						'Archivo inválido, solo archivos .pdf, .png, .jpg, .jpeg')
				: delete formErrors.doc_dni;
			break;
		case 'doc_medical_title':
			const typeTitle = value && value.type ? value.type : null;
			!(
				typeTitle === 'application/pdf' ||
				typeTitle === 'image/jpeg' ||
				typeTitle === 'image/jpg' ||
				typeTitle === 'image/png'
			)
				? (formErrors.doc_medical_title =
						'Archivo inválido, solo archivos .pdf, .png, .jpg, .jpeg')
				: delete formErrors.doc_medical_title;
			break;
		case 'video':
			const typeVideo = value && value.type ? value.type : null;
			!(
				typeVideo === 'video/mov' ||
				typeVideo === 'video/avi' ||
				typeVideo === 'video/wmv' ||
				typeVideo === 'video/mp4' ||
				typeVideo === 'video/quicktime' ||
				value === null
			)
				? (formErrors.video =
						'Archivo inválido, solo archivos .mp4, .avi, .mov, .wmv')
				: delete formErrors.video;
			break;
		default:
			break;
	}
	return { formErrors };
};

const validateFieldsDemographic = (
	formErrors,
	formErrorsDemographic,
	field,
	value,
	index
) => {
	let validate = false;

	switch (field) {
		case 'sex':
			validate = /^([a-zA-Z0-9ñÑáéíóúÁÉÍÓÚüÜ,.:;!@#$?¿%^&*()-_  ]{1,})$/.test(
				value
			);
			if (!validate) {
				formErrorsDemographic.sex[index] = 'Select gender.';
				formErrors.sex = 'Select gender.';
			} else {
				formErrorsDemographic.sex && formErrorsDemographic.sex.splice(index, 1);
				delete formErrors.sex;
			}
			break;
		case 'price_by_age':
			validate = /^([0-9.,]{1,})$/.test(value) && value >= 0;
			if (!validate) {
				formErrorsDemographic.price_by_age[index] = 'Precio inválido';
				formErrors.price_by_age = 'Precio inválido';
			} else {
				formErrorsDemographic.price_by_age &&
					formErrorsDemographic.price_by_age.splice(index, 1);
				delete formErrors.price_by_age;
			}
			break;
		case 'min_age':
			validate = /^([0-9]{1,})$/.test(value) && value >= 0;
			if (!validate) {
				formErrorsDemographic.min_age[index] = 'Edad inválida';
				formErrors.min_age = 'Edad inválida';
			} else {
				formErrorsDemographic.min_age &&
					formErrorsDemographic.min_age.splice(index, 1);
				delete formErrors.min_age;
			}
			break;
		case 'max_age':
			validate = /^([0-9]{1,})$/.test(value) && value >= 0;
			if (!validate) {
				formErrorsDemographic.max_age[index] = 'Edad inválida';
				formErrors.max_age = 'Edad inválida';
			} else {
				formErrorsDemographic.max_age &&
					formErrorsDemographic.max_age.splice(index, 1);
				delete formErrors.max_age;
			}
			break;
		case 'coverage':
			validate = /^([0-9.,]{1,})$/.test(value) && value >= 0;
			if (!validate) {
				formErrorsDemographic.coverage[index] = 'Cobertura inválido';
				formErrors.coverage = 'Cobertura inválido';
			} else {
				formErrorsDemographic.coverage &&
					formErrorsDemographic.coverage.splice(index, 1);
				delete formErrors.coverage;
			}
			break;
		default:
			break;
	}
	return { formErrors, formErrorsDemographic };
};

const validateFieldPassword = (field, value, formErrors, signUpPass) => {
	let form = formErrors;
	let validate = false;
	switch (field) {
		case 'password':
			validate =
				/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@_#\$%\^&\*\.\+\-])(?=.{8,20})/.test(
					value
				);

			!validate
				? (form.password =
						'Please choose a password with at least 8 characters that includes at least 1 uppercase character, 1 number, and 1 special character "!@#$%^&*.+')
				: delete form.password;
			if (signUpPass?.password && !form.password) {
				value === signUpPass?.password_confirm
					? delete form.password_confirm
					: (form.password_confirm =
							'Password did not match. Please try again.');
			}
			break;
		case 'password_confirm':
			validate = value === signUpPass?.password;
			!validate
				? (form.password_confirm = 'Password did not match. Please try again.')
				: delete form.password_confirm;
			break;
		case 'email':
			validate = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(value);
			!validate ? (form.email = 'Invalid email.') : delete form.email;
			break;
		case 'code':
			validate = /^[0-9]*$/.test(value);
			!validate
				? (form.code = 'Invalid code, must be numeric.')
				: delete form.code;
			break;
		default:
			break;
	}
	return form;
};
const validateFieldPasswordOnlyCharacters = (
	field,
	value,
	formErrors,
	signUpPass
) => {
	let form = formErrors;
	let validate = false;
	switch (field) {
		case 'password':
			validate = /^([a-zA-Z0-9ñÑáéíóúÁÉÍÓÚüÜ !@#\$%\^&\*\.\+]{8,})$/.test(
				value
			);

			!validate
				? (form.password =
						'Please choose a password with at least 8 characters that includes at least 1 uppercase character, 1 number, and 1 special character "!@#$%^&*.+')
				: delete form.password;
			if (signUpPass?.password && !form.password) {
				value === signUpPass?.password_confirm
					? delete form.password_confirm
					: (form.password_confirm =
							'Password did not match. Please try again.');
			}
			break;
		default:
			break;
	}
	return form;
};

const validatePhoneLength = (phone, countryCode) => {
	console.log(countryCode);
	const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
	let isValid = false;
	try {
		const number = phoneUtil.parseAndKeepRawInput(
			`+${phone}`,
			countryCode.toUpperCase()
		);
		isValid = phoneUtil.isValidNumber(number);
	} catch (error) {
		isValid = false;
	}
	return {
		isValid: isValid,
		error: !isValid ? 'Enter a valid phone number.' : ''
	};
};

const validateLocation = (name, value, errors) => {
	let formErrors = { ...errors };
	let validate = false;
	switch (name) {
		case 'country':
			validate = value !== '' && value !== 'default';
			!validate
				? (formErrors.country = 'Debe seleccionar un país válido.')
				: delete formErrors.country;
			break;
		case 'state':
			validate =
				/^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ()[\] ,.-]{2,})$/.test(value) &&
				value !== 'default';
			!validate
				? (formErrors.state = 'Select province')
				: delete formErrors.state;
			break;
		case 'municipality':
			validate =
				/^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ()[\] ,.-]{2,})$/.test(value) &&
				value !== 'default';
			!validate
				? (formErrors.municipality = 'Select city')
				: delete formErrors.municipality;
			break;
		case 'city':
			validate =
				/^[a-zA-ZñÑáéíóúÁÉÍÓÚüÜ0-9 _.,\-!()+=“”„@"$#%*]{2,}$/.test(value) &&
				value !== 'default';
			!validate
				? (formErrors.city = 'Select barangay.')
				: delete formErrors.city;
			break;

		default:
			break;
	}
	return formErrors;
};

export default {
	validateFieldHeadLine,
	validateFieldHeadLineRequired,
	validateTitularForm,
	validateFieldBeneficiaries,
	validatePaymentForm,
	validateFieldBeneficiariesRequired,
	validateFieldContact,
	validFieldInitAffiliation,
	validateFieldsProduct,
	validateOptionsProduct,
	validateServicesProduct,
	validateFieldsTariffs,
	validateFieldsAddress,
	validateFieldsPaymentForms,
	validateFieldsCoupons,
	validateFieldsBasicCoupons,
	validateFieldsVoluntary,
	isValidDate,
	validateFieldsDemographic,
	validateFieldPassword,
	validateFieldPasswordOnlyCharacters,
	validatePhoneLength,
	validateLocation
};
