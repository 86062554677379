import {
	SEARCHDASHBOARD,
	VIEWREGISTRY,
	PENDINGDASHBOARD,
	SEARCHPAGES,
	SEARCHROWSPERPAGE,
	TABPOSITION,
	BACKTODASHBOARD,
	GETCAMPAINGS,
	GETCAMPAIGNINFO,
	SAVE_MEDICAL_CONSULTS,
	SAVE_CONSULT_BY_ID,
	VOLUNTEERS_BY_SPECIALTY,
	NEXT_AFFILIATION_DATA,
	SET_CURRENT_COUNTRY,
	SET_CURRENT_SEARCH_DATE,
	RENEWSUBSCRIPTIONS
	// CREATECAMPAIGNS,
} from '../actionTypes';
import Common from '../../src/services/common.js';

const FileDownload = require('js-file-download');

export function searchDashboard(values) {
	return async (dispatch) => {
		return await Common.search_customer(values)
			.then(async (res) => {
				await dispatch({
					type: SEARCHDASHBOARD,
					payload: {
						values: {
							data: res.data,
							isSearch: true
						}
					}
				});
				let result = {
					spinnerScreen: false
				};
				if (res.data.docs.length === 0) {
					result.openAlert = true;
					result.messageAlert = 'La búsqueda no existe';
				}
				return result;
			})
			.catch((e) => {
				return {
					openAlert: true,
					spinnerScreen: false,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'Hubo un error al procesar la búsqueda, intente nuevamente.'
				};
			});
	};
}

export function getNextSearchData(values, type) {
	return async (dispatch) => {
		return await Common.search_customer(values)
			.then(async (res) => {
				await dispatch({
					type: NEXT_AFFILIATION_DATA,
					payload: {
						values: {
							data: res.data,
							type
						}
					}
				});
				let result = {
					spinnerScreen: false
				};
				return result;
			})
			.catch((e) => {
				return {
					spinnerScreen: false,
					openAlert: true,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'Hubo un error al procesar su solicitud, intente nuevamente.'
				};
			});
	};
}

export function getNextAffiliationData(skip = 0, limit = 5, type) {
	return async (dispatch) => {
		return await Common.data_customers({ skip, limit })
			.then(async (res) => {
				await dispatch({
					type: NEXT_AFFILIATION_DATA,
					payload: {
						values: {
							data: res.data,
							type
						}
					}
				});
				let result = {
					spinnerScreen: false
				};
				return result;
			})
			.catch((e) => {
				return {
					spinnerScreen: false,
					openAlert: true,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'Hubo un error al procesar su solicitud, intente nuevamente.'
				};
			});
	};
}

export function searchDashboardBeneficiaries(values) {
	return async (dispatch) => {
		return await Common.search_beneficiaries(values)
			.then(async (res) => {
				await dispatch({
					type: SEARCHDASHBOARD,
					payload: {
						values: {
							data: res.data,
							isSearch: true
						}
					}
				});
				let result = {
					spinnerProgress: false,
					result: res.data,
					newSearch: true
				};
				if (res.data.length === 0) {
					result.openAlert = true;
					result.messageAlert = 'La búsqueda no existe';
				}
				return result;
			})
			.catch((e) => {
				return {
					openAlert: true,
					spinnerProgress: false,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'Hubo un error al procesar la búsqueda, intente nuevamente.'
				};
			});
	};
}

export function view_registry(values) {
	return async (dispatch) => {
		return await Common.view_registry(values)
			.then(async (res) => {
				await dispatch({
					type: VIEWREGISTRY,
					payload: {
						values: {
							data: res.data
						}
					}
				});
				return {
					spinnerScreen: false,
					view_registry_all: true,
					data_customer: res.data,
					success: true
				};
			})
			.catch((e) => {
				return {
					success: false,
					openAlert: true,
					spinnerScreen: false,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'Hubo un error al solicitar la información del registro, intente nuevamente.'
				};
			});
	};
}

export function view_titular_data(values) {
	return async (dispatch) => {
		return await Common.view_titular_registry(values)
			.then(async (res) => {
				await dispatch({
					type: VIEWREGISTRY,
					payload: {
						values: {
							data: res.data
						}
					}
				});
				let result = {
					spinnerScreen: false,
					view_registry_all: true,
					data_customer: res.data
				};
				return result;
			})
			.catch((e) => {
				return {
					openAlert: true,
					spinnerScreen: false,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'Hubo un error al solicitar la información del registro, intente nuevamente.'
				};
			});
	};
}

export function sendDocumentation(data) {
	return async (dispatch) => {
		return await Common.send_documentation(data)
			.then(() => {
				return {
					spinnerScreen: false,
					openAlert: true,
					messageAlert: 'Documentos enviados exitosamente.'
				};
			})
			.catch((e) => {
				return {
					spinnerScreen: false,
					openAlert: true,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'There was an error sending the documents, please try again.'
				};
			});
	};
}

export function dataPending() {
	return async (dispatch) => {
		return await Common.data_pending()
			.then(async (res) => {
				await dispatch({
					type: PENDINGDASHBOARD,
					payload: {
						values: {
							data: res.data.docs
						}
					}
				});
				let result = {
					spinnerScreen: false
				};
				return result;
			})
			.catch((e) => {
				return {
					spinnerScreen: false,
					openAlert: true,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'Hubo un error al obtener los pagos fallidos, intente nuevamente.'
				};
			});
	};
}

export function searchPages(values) {
	return async (dispatch) => {
		await dispatch({
			type: SEARCHPAGES,
			payload: {
				values
			}
		});
		return true;
	};
}

export function searchRowsPerPage(values) {
	return async (dispatch) => {
		await dispatch({
			type: SEARCHROWSPERPAGE,
			payload: {
				values
			}
		});
		return true;
	};
}

export function tabPosition(values) {
	return async (dispatch) => {
		await dispatch({
			type: TABPOSITION,
			payload: {
				values
			}
		});
		return true;
	};
}

export function backToDashboard(values) {
	return async (dispatch) => {
		await dispatch({
			type: BACKTODASHBOARD,
			payload: {
				values
			}
		});
		return true;
	};
}

export function dataDashboard(skip = 0, limit = 5) {
	return async (dispatch) => {
		return await Common.data_customers({ skip, limit })
			.then(async (res) => {
				await dispatch({
					type: SEARCHDASHBOARD,
					payload: {
						values: {
							data: res.data,
							isSearch: false
						}
					}
				});
				let result = {
					result: res.data.docs,
					newSearch: true,
					spinnerScreen: false
				};
				return result;
			})
			.catch((e) => {
				return {
					spinnerScreen: false,
					openAlert: true,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'Hubo un error al procesar su solicitud, intente nuevamente.'
				};
			});
	};
}

export function getMarketing() {
	return async (dispatch) => {
		return await Common.get_marketing()
			.then(async (res) => {
				await dispatch({
					type: GETCAMPAINGS,
					payload: {
						values: {
							data: res.data.marketing,
							spinnerScreen: false
						}
					}
				});
				let result = {
					data: res.data.marketing,
					spinnerScreen: false
				};
				return result;
			})
			.catch((e) => {
				return {
					spinnerScreen: false,
					openAlert: true,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'Hubo un error al obtener las campañas, intente nuevamente.'
				};
			});
	};
}

export function getCampaignInfo(id) {
	return async (dispatch) => {
		return await Common.view_marketing(id)
			.then(async (res) => {
				await dispatch({
					type: GETCAMPAIGNINFO,
					payload: {
						values: {
							data: res.data.campaign,
							spinnerScreen: false
						}
					}
				});
				let result = {
					data_campaign: res.data.campaign,
					spinnerScreen: false
				};
				return result;
			})
			.catch((e) => {
				return {
					spinnerScreen: false,
					openAlert: true,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'Hubo un error al obtener la información de la campaña, intente nuevamente.'
				};
			});
	};
}

export function export_campaign(filter) {
	return async () => {
		return await Common.export_campaign({ responseType: 'blob' }, { ...filter })
			.then((r) => {
				FileDownload(r.data, 'leads.xlsx');
				return {
					spinnerScreen: false,
					showDialogExport: false,
					is_success: true
				};
			})
			.catch((e) => {
				return {
					is_success: false,
					spinnerScreen: false,
					openAlert: true,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'Hubo un error al descargar los leads, intente nuevamente.'
				};
			});
	};
}

// MEDICAL DASHBOARD

export function getMedicalConsults() {
	return async (dispatch) => {
		return await Common.get_medical_consults()
			.then(async (res) => {
				await dispatch({
					type: SAVE_MEDICAL_CONSULTS,
					payload: {
						values: res.data.data
					}
				});
				let result = {
					spinnerScreen: false
				};
				return result;
			})
			.catch((e) => {
				return {
					spinnerScreen: false,
					openAlert: true,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'Hubo un error al obtener las consultas médicas, intente nuevamente.'
				};
			});
	};
}

export function getConsultById(id) {
	return async (dispatch) => {
		return await Common.get_consult_by_id(id)
			.then(async (res) => {
				await dispatch({
					type: SAVE_CONSULT_BY_ID,
					payload: {
						values: res.data.data
					}
				});
				let result = {
					success: true
				};
				return result;
			})
			.catch((e) => {
				return {
					success: false,
					openAlert: true,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'Hubo un error al obtener los datos de la consulta, intente nuevamente.'
				};
			});
	};
}

export function addTracing(values) {
	return async (dispatch) => {
		return await Common.add_tracing(values)
			.then((res) => {
				return {
					success: true,
					openAlert: true,
					messageAlert: 'Seguimiento actualizado exitosamente.'
				};
			})
			.catch((e) => {
				return {
					success: false,
					openAlert: true,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'Hubo un error al agregar el seguimiento, intente nuevamente.'
				};
			});
	};
}

export function save_tracing_consult(values) {
	return async (dispatch) => {
		await dispatch({
			type: SAVE_CONSULT_BY_ID,
			payload: {
				values
			}
		});
		return true;
	};
}

export function getMedicalData() {
	return async () => {
		return await Common.export_medical_consults({ responseType: 'blob' })
			.then((r) => {
				FileDownload(r.data, 'consultas_medicas.xlsx');
				return {
					showSpinner: false
				};
			})
			.catch((e) => {
				return {
					showSpinner: false,
					openAlert: true,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'Hubo un error al descargar las consultas, intente nuevamente.'
				};
			});
	};
}

export const getAttentionData = (attentionID) => {
	return async (dispatch) => {
		return await Common.get_data_attention(attentionID)
			.then(async (res) => {
				await dispatch({
					type: SAVE_CONSULT_BY_ID,
					payload: {
						values: res.data.consultation
					}
				});
				let result = {
					success: true
				};
				return result;
			})
			.catch((e) => {
				return {
					success: false,
					openAlert: true,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'Hubo un error al obtener los datos de la consulta, intente nuevamente.'
				};
			});
	};
};

export function getConsultsAlert() {
	return async (dispatch) => {
		return await Common.get_consults_alert()
			.then(async (res) => {
				await dispatch({
					type: SAVE_MEDICAL_CONSULTS,
					payload: {
						values: res.data.data
					}
				});
				let result = {
					spinnerScreen: false
				};
				return result;
			})
			.catch((e) => {
				return {
					spinnerScreen: false,
					openAlert: true,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'Hubo un error al obtener las consultas médicas, intente nuevamente.'
				};
			});
	};
}

export function getVolunteersBySpecialty(specialty) {
	return async (dispatch) => {
		return await Common.get_volunteers_by_specialty(specialty)
			.then(async (res) => {
				await dispatch({
					type: VOLUNTEERS_BY_SPECIALTY,
					payload: {
						values: res.data.data
					}
				});
				let result = {
					success: true,
					spinnerScreen: false
				};
				return result;
			})
			.catch((e) => {
				return {
					spinnerScreen: false,
					success: false,
					openAlert: true,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'Hubo un error al obtener los datos de los voluntarios, intente nuevamente.'
				};
			});
	};
}

export function AssignmentVolunteer(data) {
	return async (dispatch) => {
		return await Common.assignment_volunteer(data)
			.then((res) => {
				return {
					spinnerScreen: false,
					success: true,
					openAlert: true,
					messageAlert: 'El voluntario a sido asignado exitosamente.'
				};
			})
			.catch((e) => {
				return {
					spinnerScreen: false,
					success: false,
					openAlert: true,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'Hubo un error al asignar el voluntario, intente nuevamente.'
				};
			});
	};
}

export function changeConsultStatus(values) {
	return async (dispatch) => {
		return await Common.change_consultation_status(values)
			.then((res) => {
				return {
					showEdit: false,
					loadding: false,
					openAlert: true,
					messageAlert: 'Estatus actualizado exitosamente.'
				};
			})
			.catch((e) => {
				return {
					loadding: false,
					openAlert: true,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'Hubo un error al cambiar el estatus, intente nuevamente.'
				};
			});
	};
}

export function filterMedicalData(data) {
	return async (dispatch) => {
		return await Common.filter_consultation(data)
			.then(async (res) => {
				await dispatch({
					type: SAVE_MEDICAL_CONSULTS,
					payload: {
						values: res.data.data
					}
				});
				let result = {
					spinnerScreen: false
				};
				return result;
			})
			.catch((e) => {
				return {
					spinnerScreen: false,
					openAlert: true,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'Hubo un error al filtrar los datos, intente nuevamente.'
				};
			});
	};
}

export function getRenewalSubscriptions() {
	return async (dispatch) => {
		return await Common.get_renewal_subscriptions()
			.then(async (res) => {
				await dispatch({
					type: RENEWSUBSCRIPTIONS,
					payload: {
						values: {
							data: res.data.customers
						}
					}
				});
				let result = {
					spinnerScreen: false
				};
				return result;
			})
			.catch((e) => {
				return {
					spinnerScreen: false,
					openAlert: true,
					messageAlert: e?.response?.data?.error?.message
						? e.response.data.error.message
						: 'Hubo un error al obtener la información, intente nuevamente.'
				};
			});
	};
}

export function getLegalDocument(route) {
	return async (dispath) => {
		return await Common.get_legal_document(route)
			.then(async (res) => {
				return {
					content: res?.data?.content || '',
					success: true,
					spinnerScreen: false
				};
			})
			.catch((e) => {
				return {
					openAlert: true,
					spinnerScreen: false,
					success: false,
					messageAlert:
						e?.response && e?.response?.data
							? e?.response?.data?.error?.message
							: 'Hubo un error al obtener el documento, intente nuevamente.'
				};
			});
	};
}
