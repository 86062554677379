import { SET_AWARD, SET_AWARDS } from '../actionTypes';
import Common from '../../src/services/common.js';

export const getAllAwards = () => {
	return async (dispatch) => {
		return Common.get_awards()
			.then(async (r) => {
				await dispatch({
					type: SET_AWARDS,
					payload: r.data.awards
				});
				return { spinnerScreen: false };
			})
			.catch((e) => {
				return {
					spinnerScreen: false,
					openAlert: true,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'Hubo un error al obtener los premios, intente nuevamente.'
				};
			});
	};
};

export const createAward = (data) => {
	return async (dispatch) => {
		return Common.create_award(data)
			.then(() => {
				return {
					success: true,
					spinnerScreen: false,
					showDialog: false,
					openAlert: true,
					messageAlert: 'Nuevo premio registrado exitosamente.'
				};
			})
			.catch((e) => {
				return {
					spinnerScreen: false,
					openAlert: true,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'Hubo un error al crear el premio, intente nuevamente.'
				};
			});
	};
};

export const updateAward = (id, data) => {
	return async (dispatch) => {
		return Common.update_award(id, data)
			.then(() => {
				return {
					success: true,
					spinnerScreen: false,
					showDialog: false,
					openAlert: true,
					messageAlert: 'Premio actualizado exitosamente.'
				};
			})
			.catch((e) => {
				return {
					spinnerScreen: false,
					openAlert: true,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'Hubo un error al actualizar el premio, intente nuevamente.'
				};
			});
	};
};

export const deleteAward = (data) => {
	return async (dispatch) => {
		return Common.delete_award(data)
			.then(() => {
				return {
					success: true,
					spinnerScreen: false,
					showConfirm: false,
					openAlert: true,
					messageAlert: 'Premio eliminado exitosamente.'
				};
			})
			.catch((e) => {
				return {
					spinnerScreen: false,
					openAlert: true,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'Hubo un error al eliminar el premio, intente nuevamente.'
				};
			});
	};
};

export const exchangeAward = (id) => {
	return async (dispatch) => {
		return Common.exchange_award(id)
			.then(() => {
				return {
					success: true,
					spinnerScreen: false,
					showConfirm: false,
					openAlert: true,
					messageAlert: 'Premio canjeado exitosamente.'
				};
			})
			.catch((e) => {
				return {
					spinnerScreen: false,
					openAlert: true,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'Hubo un error al canjear el premio, intente nuevamente.'
				};
			});
	};
};

export const setAwardData = (data) => {
	return async (dispatch) => {
		return await dispatch({
			type: SET_AWARD,
			payload: data
		});
	};
};
