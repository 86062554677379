import {
	SAVE_VOLUNTARY_DATA,
	CLEAN_VOLUNTARY_DATA,
	SAVE_CONSULTATION_FEEDBACK,
	CLEAN_MEDICAL_CONSULT,
	GET_VOLUNTEERS
} from '../actionTypes';
import Common from '../../src/services/common.js';

export const getAllVolunteers = () => async (dispatch) => {
	return await Common.get_all_volunteers()
		.then(async (r) => {
			await dispatch({
				type: GET_VOLUNTEERS,
				payload: r.data
			});
			return { spinnerScreen: false };
		})
		.catch((e) => {
			return {
				spinnerScreen: false,
				openAlert: true,
				messageAlert:
					e.response && e.response.data
						? e.response.data.error.message
						: 'Hubo un error al obtener los voluntarios, intente nuevamente.'
			};
		});
};

export const filterVolunteers = (data) => async (dispatch) => {
	return await Common.filter_volunteers(data)
		.then(async (r) => {
			await dispatch({
				type: GET_VOLUNTEERS,
				payload: r.data
			});
			return { spinnerScreen: false };
		})
		.catch((e) => {
			return {
				spinnerScreen: false,
				openAlert: true,
				messageAlert:
					e.response && e.response.data
						? e.response.data.error.message
						: 'Hubo un error al obtener los voluntarios, intente nuevamente.'
			};
		});
};

export const updateVolunteers = (value) => async (dispatch) => {
	return await Common.update_volunteers(value)
		.then(async (r) => {
			return {
				success: true,
				spinnerScreen: false,
				showDialog: false,
				openAlert: true,
				messageAlert: 'Voluntario actualizado exitosamente.'
			};
		})
		.catch((e) => {
			return {
				spinnerScreen: false,
				openAlert: true,
				messageAlert:
					e.response && e.response.data
						? e.response.data.error.message
						: 'Hubo un error al actualizar el voluntario, intente nuevamente.'
			};
		});
};

export const saveVoluntaryData = (values) => {
	return async (dispatch) => {
		return await dispatch({
			type: SAVE_VOLUNTARY_DATA,
			payload: {
				values
			}
		});
	};
};

export const sendVoluntaryData = (data) => {
	return async (dispatch) => {
		return await Common.send_voluntary_data(data)
			.then((r) => {
				return {
					success: true,
					loadding: false,
					voluntaryID: r.data.voluntaryID
				};
			})
			.catch((e) => {
				return {
					success: false,
					loadding: false,
					openAlert: true,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'Hubo un error al enviar sus datos, intente nuevamente.'
				};
			});
	};
};

export const sendVoluntaryCode = (data) => {
	return async (dispatch) => {
		return await Common.send_voluntary_code(data)
			.then(() => {
				return {
					loadding: false,
					success: true
				};
			})
			.catch((e) => {
				return {
					success: false,
					loadding: false,
					openAlert: true,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'Hubo un error al enviar el código, intente nuevamente.'
				};
			});
	};
};

export const cleanVoluntaryData = () => async (dispatch) =>
	await dispatch({ type: CLEAN_VOLUNTARY_DATA, payload: {} });

export const saveFeedback = (values) => {
	return async (dispatch) => {
		return await dispatch({
			type: SAVE_CONSULTATION_FEEDBACK,
			payload: {
				values
			}
		});
	};
};

export const processMedicalConsult = (data) => {
	return async (dispatch) => {
		return await Common.process_medical_consult(data)
			.then((r) => {
				return {
					loadding: false,
					success: true,
					openAlert: true,
					messageAlert:
						r.data && r.data.message
							? r.data.message
							: 'Gracias, los datos de la consulta han sido guardados exitosamente.'
				};
			})
			.catch((e) => {
				return {
					success: false,
					loadding: false,
					openAlert: true,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'Hubo un error al enviar los datos, intente nuevamente.'
				};
			});
	};
};

export const cleanMedicalConsult = () => async (dispatch) =>
	await dispatch({ type: CLEAN_MEDICAL_CONSULT, payload: {} });
