import axios from 'axios';
import Router from 'next/router';

const serverUrl = process.env.REACT_APP_SERVER_URL;
const COUNTRY_CODE = process.env.REACT_APP_COUNTRY_CODE_RESUME;

const API = (options) => {
	const instance = axios.create({
		headers: {
			Authorization: `Bearer ${localStorage.getItem('token')}`,
			country_code: COUNTRY_CODE,
			'Accept-Language': 'en-US'
		},
		withCredentials: true,
		baseURL: `${serverUrl}/api/`,
		...options
	});

	instance.interceptors.response.use(
		(response) => response,
		async (error) => {
			let { status, data } = error.response;

			if (status === 401 || status === 403) {
				await localStorage.clear();

				const isMobile = Router?.router?.asPath?.includes('/m');

				if (isMobile) {
					Router.push({
						pathname: '/m/error-mobile',
						query: { refresh: false }
					});
				} else {
					if (status === 403) {
						Router.push({
							pathname: '/authentication/signIn',
							query: { refresh: true }
						});
						return;
					}

					await Router.push('/authentication/signIn', '/login');
				}
			}

			if (
				error.request.responseType === 'blob' &&
				data instanceof Blob &&
				data.type &&
				data.type.toLowerCase().indexOf('json') != -1
			) {
				return new Promise((resolve, reject) => {
					let reader = new FileReader();
					reader.onload = () => {
						data = JSON.parse(reader.result);
						resolve(Promise.reject(error));
					};

					reader.onerror = () => {
						reject(error);
					};

					reader.readAsText(data);
				});
			}
			return Promise.reject(error);
		}
	);

	return instance;
};

export default API;
