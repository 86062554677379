import {
	CLEAN_PROPOSAL,
	GET_PROPOSALS,
	RESET_CURRENT_CAMPAIGN,
	SET_PROPOSAL,
	UPDATE_CURRENT_CAMPAIGN
} from '../actionTypes';
import Common from '../../src/services/common.js';

export const createMarketingCampaign = (campaign) => {
	return async (dispatch) => {
		return await Common.marketing(campaign)
			.then(async () => {
				return {
					data: {
						openAlert: true,
						messageAlert: 'La campaña ha sido creado exitosamente',
						showDialog: false
					},
					success: true
				};
			})
			.catch((e) => {
				return {
					data: {
						openAlert: true,
						messageAlert:
							e.response && e.response.data
								? e.response.data.error.message
								: 'Hubo un error al crear la campaña, intente nuevamente.'
					},
					success: false
				};
			});
	};
};

export const updateMarketingCampaign = (campaign) => {
	return async (dispatch) => {
		return await Common.update_marketing(campaign)
			.then(async () => {
				return {
					data: {
						showDialog: false,
						openAlert: true,
						messageAlert: 'La campaña ha sido actualizado exitosamente.'
					},
					success: true
				};
			})
			.catch((e) => {
				return {
					data: {
						openAlert: true,
						messageAlert:
							e.response && e.response.data
								? e.response.data.error.message
								: 'Ha ocurrido un error al actualizar la información, intente nuevamente.'
					},
					success: false
				};
			});
	};
};

export const deleteMarketingCampaign = (campaign_id) => {
	return async (dispatch) => {
		return await Common.delete_marketing({ _id: campaign_id })
			.then(async (update) => {
				return {
					data: {
						...update,
						showConfirm: false,
						spinnerButton: false,
						propsConfirm: null,
						openAlert: true,
						messageAlert: 'La campaña ha sido eliminado.'
					},
					success: true
				};
			})
			.catch((e) => {
				return {
					data: {
						showConfirm: false,
						spinnerButton: false,
						openAlert: true,
						messageAlert:
							e.response && e.response.data
								? e.response.data.error.message
								: 'Hubo un error al eliminar la campaña, intente nuevamente.'
					},
					success: false
				};
			});
	};
};

export const updateCurrentCampaign = (campaign) => ({
	type: UPDATE_CURRENT_CAMPAIGN,
	payload: campaign
});

export const resetCurrentCampaign = () => ({
	type: RESET_CURRENT_CAMPAIGN
});

export const getAllProposals = () => async (dispatch) => {
	return await Common.get_proporsal_value()
		.then(async (r) => {
			await dispatch({
				type: GET_PROPOSALS,
				payload: r.data
			});
			return { spinnerScreen: false };
		})
		.catch((e) => {
			return {
				spinnerScreen: false,
				openAlert: true,
				messageAlert:
					e.response && e.response.data
						? e.response.data.error.message
						: 'Hubo un error al obtener las propuestas de valor, intente nuevamente.'
			};
		});
};

export const createProposal = (value) => async (dispatch) => {
	return await Common.create_proposal_value(value)
		.then((r) => {
			return {
				success: true,
				spinnerScreen: false,
				showDialog: false,
				openAlert: true,
				messageAlert: 'Nueva propuesta registrada exitosamente.'
			};
		})
		.catch((e) => ({
			spinnerScreen: false,
			openAlert: true,
			messageAlert:
				e.response && e.response.data
					? e.response.data.error.message
					: 'Hubo un error al crear la propuesta, intente nuevamente.'
		}));
};

export const updateProposal = (value) => async (dispatch) => {
	return await Common.update_proposal_value(value)
		.then((r) => {
			return {
				success: true,
				spinnerScreen: false,
				showDialog: false,
				openAlert: true,
				messageAlert: 'Propuesta actualizada exitosamente.'
			};
		})
		.catch((e) => ({
			spinnerScreen: false,
			openAlert: true,
			messageAlert:
				e.response && e.response.data
					? e.response.data.error.message
					: 'Hubo un error al actualizar la propuesta, intente nuevamente.'
		}));
};

export const setProposal = (value) => ({
	type: SET_PROPOSAL,
	payload: value
});

export const cleanProposal = () => ({
	type: CLEAN_PROPOSAL
});
