import { GET_VERIFICATIONS, SET_VERIFICATION } from '../actionTypes';
import Common from '../../src/services/common.js';

export const getAllVerifications = () => async (dispatch) => {
	return await Common.get_verifications()
		.then(async (r) => {
			await dispatch({
				type: GET_VERIFICATIONS,
				payload: r.data.list_beneficiaries
			});
			return { spinnerScreen: false };
		})
		.catch((e) => {
			return {
				spinnerScreen: false,
				openAlert: true,
				messageAlert:
					e.response && e.response.data
						? e.response.data.error.message
						: 'Hubo un error al obtener las verificaciones, intente nuevamente.'
			};
		});
};

export const uploadSupport = (id, value) => async (dispatch) => {
	return await Common.upload_support(id, value)
		.then(async (r) => {
			return {
				success: true,
				spinnerScreen: false,
				showDialog: false,
				openAlert: true,
				messageAlert: 'Soporte cargado exitosamente.'
			};
		})
		.catch((e) => {
			return {
				spinnerScreen: false,
				openAlert: true,
				messageAlert:
					e.response && e.response.data
						? e.response.data.error.message
						: 'Hubo un error al actualizar la verificación, intente nuevamente.'
			};
		});
};

export const evaluateBeneficiary = (id, value) => async (dispatch) => {
	return await Common.evaluate_beneficiary(id, value)
		.then(async (r) => {
			return {
				success: true,
				spinnerScreen: false,
				showDialog: false,
				openAlert: true,
				messageAlert: 'Evaluación cargada exitosamente.'
			};
		})
		.catch((e) => {
			return {
				spinnerScreen: false,
				openAlert: true,
				messageAlert:
					e.response && e.response.data
						? e.response.data.error.message
						: 'Hubo un error al evaluar al beneficiario, intente nuevamente.'
			};
		});
};

export const saveVerificationData = (values) => {
	return async (dispatch) => {
		return await dispatch({
			type: SET_VERIFICATION,
			payload: values
		});
	};
};

export const cleanVerificationData = () => async (dispatch) =>
	await dispatch({ type: SET_VERIFICATION, payload: {} });
