import {
	SET_BIGBRODER,
	SET_BIGBRODERS,
	SET_BIGBRODERS_CANDIDATE
} from '../actionTypes';
import Common from '../../src/services/common.js';

export const getAllBrodersCandidate = () => {
	return async (dispatch) => {
		return Common.get_big_broders_candidate()
			.then(async (r) => {
				await dispatch({
					type: SET_BIGBRODERS_CANDIDATE,
					payload: r.data.candidates
				});
				return { spinnerScreen: false };
			})
			.catch((e) => {
				return {
					spinnerScreen: false,
					openAlert: true,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'Hubo un error al obtener los big broders, intente nuevamente.'
				};
			});
	};
};

export const getAllBroders = () => {
	return async (dispatch) => {
		return Common.get_big_broders()
			.then(async (r) => {
				await dispatch({
					type: SET_BIGBRODERS,
					payload: r.data.big_broders
				});
				return { spinnerScreen: false };
			})
			.catch((e) => {
				return {
					spinnerScreen: false,
					openAlert: true,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'Hubo un error al obtener los big broders, intente nuevamente.'
				};
			});
	};
};

export const createBroder = (data) => {
	return async (dispatch) => {
		return Common.create_broder(data)
			.then(() => {
				return {
					success: true,
					spinnerScreen: false,
					showDialog: false,
					openAlert: true,
					messageAlert: 'Nuevo Bigbroder registrado exitosamente.'
				};
			})
			.catch((e) => {
				return {
					spinnerScreen: false,
					openAlert: true,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'Hubo un error al crear el Bigbroder, intente nuevamente.'
				};
			});
	};
};

export const updateBroder = (id, data) => {
	return async (dispatch) => {
		return Common.update_broder(id, data)
			.then(() => {
				return {
					success: true,
					spinnerScreen: false,
					showDialog: false,
					openAlert: true,
					messageAlert: 'Bigbroder actualizado exitosamente.'
				};
			})
			.catch((e) => {
				return {
					spinnerScreen: false,
					openAlert: true,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'Hubo un error al actualizar el Bigbroder, intente nuevamente.'
				};
			});
	};
};

export const deleteBroder = (data) => {
	return async (dispatch) => {
		return Common.delete_broder(data)
			.then(() => {
				return {
					success: true,
					spinnerScreen: false,
					showConfirm: false,
					openAlert: true,
					messageAlert: 'Bigbroder eliminado exitosamente.'
				};
			})
			.catch((e) => {
				return {
					spinnerScreen: false,
					openAlert: true,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'Hubo un error al eliminar el Bigbroder, intente nuevamente.'
				};
			});
	};
};

export const assingBroder = (data) => {
	return async (dispatch) => {
		return Common.assign_broder(data)
			.then(() => {
				return {
					success: true,
					spinnerScreen: false,
					showConfirm: false,
					openAlert: true,
					messageAlert: 'Bigbroder asignado exitosamente.'
				};
			})
			.catch((e) => {
				return {
					spinnerScreen: false,
					openAlert: true,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'Hubo un error al asignar el Bigbroder, intente nuevamente.'
				};
			});
	};
};

export const assingBroderRandom = (data) => {
	return async (dispatch) => {
		return Common.assign_broder_random(data)
			.then(() => {
				return {
					success: true,
					spinnerScreen: false,
					showConfirm: false,
					openAlert: true,
					messageAlert: 'Bigbroder asignado exitosamente.'
				};
			})
			.catch((e) => {
				return {
					spinnerScreen: false,
					openAlert: true,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'Hubo un error al asignar el Bigbroder, intente nuevamente.'
				};
			});
	};
};

export const updateAssingBroder = (data) => {
	return async (dispatch) => {
		return Common.update_assign_broder(data)
			.then(() => {
				return {
					success: true,
					spinnerScreen: false,
					showConfirm: false,
					openAlert: true,
					messageAlert: 'Bigbroder actualizado exitosamente.'
				};
			})
			.catch((e) => {
				return {
					spinnerScreen: false,
					openAlert: true,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'Hubo un error al actualizar el Bigbroder, intente nuevamente.'
				};
			});
	};
};

export const getAllyBroderAdmin = (id) => {
	return async (dispatch) => {
		return Common.get_ally_big_broder_admin(id)
			.then(async (r) => {
				return {
					data: r.data,
					success: true
				};
			})
			.catch((e) => {
				return {
					spinnerScreen: false,
					openAlert: true,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'Hubo un error al obtener los big broders, intente nuevamente.'
				};
			});
	};
};

export const getAllyBroder = () => {
	return async (dispatch) => {
		return Common.get_ally_big_broder()
			.then(async (r) => {
				return {
					data: r.data,
					success: true
				};
			})
			.catch((e) => {
				return {
					spinnerScreen: false,
					openAlert: true,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'Hubo un error al obtener los big broders, intente nuevamente.'
				};
			});
	};
};

export const setBigbroderData = (data) => {
	return async (dispatch) => {
		return await dispatch({
			type: SET_BIGBRODER,
			payload: data
		});
	};
};
