import {
	CUSTOMER_VALIDATION,
	SELECT_CAMPAIGN,
	SELECT_PLAN,
	CHECKED_TERMS,
	CHANGE_VALUE_INPUT_AFFILIATION,
	CHANGE_STATE_AFFILIATION,
	CALCULATE_TOTAL_PAYMENT,
	CLEAN_AFFILIATION,
	CLEAN_AFFILIATION_ADMIN,
	CLEAN_FORM_ERRORS_BENEFICIARIES,
	SAVE_TOKEN,
	USE_TITULAR_DATA,
	ALL_PRODUCTS,
	SAVE_USER_TYPE,
	SAVE_TITULAR_EMAIL,
	SHOW_DISCOUNT_PERCENT,
	SET_IS_CLIENT,
	SAVE_ALLY_NAME,
	UPDATE_TITULAR_DATA,
	SET_ALL_INTERVALS,
	SET_INTERVAL,
	SAVE_USERID,
	REGISTER_FREEMIUM,
	HAS_ADDONS,
	SAVE_MOBILE_APPS_LINKS
} from '../actionTypes';
import Common from '../../src/services/common.js';
import Validations from '../../src/components/utils/Validations';
import { getHash } from '../../src/components/utils/Hash';

export const customerValidation = (values) => {
	return async (dispatch) => {
		return await Common.affiliations({ ...values })
			.then(async (res) => {
				await dispatch({
					type: CUSTOMER_VALIDATION,
					payload: {
						values: {
							data: {
								...res.data,
								init_affiliation: true
							}
						}
					}
				});
				let result = {
					showLoadding: false,
					openCampaignDialog: true,
					redirectToAfiliate:
						res.data && res.data.is_saved === true ? true : false
				};
				return result;
			})
			.catch(async (err) => {
				return {
					showLoadding: false,
					openAlert: true,
					messageAlert:
						err.response && err.response.data
							? err.response.data.error.message
							: 'There was an error validating the user, please try again',
					continue: false
				};
			});
	};
};

export const handleInputInitAffiliation =
	(nameInput, valueInput) => (dispatch) =>
		dispatch({
			type: CHANGE_VALUE_INPUT_AFFILIATION,
			payload: {
				values: {
					data: {
						nameInput,
						valueInput
					}
				}
			}
		});

export const validateFieldInitAffiliation = (formError, name, value) => {
	const formErrors = Validations.validFieldInitAffiliation(
		formError,
		name,
		value
	);

	return (dispatch) =>
		dispatch({
			type: CHANGE_VALUE_INPUT_AFFILIATION,
			payload: {
				values: {
					data: {
						name: 'formErrors',
						value: formErrors
					}
				}
			}
		});
};

export const handleSaveProducts = (values) => {
	return async (dispatch) =>
		await dispatch({
			type: ALL_PRODUCTS,
			payload: {
				values
			}
		});
};

export const handleSelectCampaign = (
	campaign_id,
	campaign_name,
	company_id,
	company_name,
	plans
) => {
	return async (dispatch) =>
		await dispatch({
			type: SELECT_CAMPAIGN,
			payload: {
				values: {
					data: {
						campaign_id,
						campaign_name,
						company_id,
						company_name,
						plans
					}
				}
			}
		});
};

export const handleSelectPlan = (product_type, index) => async (dispatch) => {
	return await dispatch({
		type: SELECT_PLAN,
		payload: {
			values: {
				data: {
					product_type,
					index
				}
			}
		}
	});
};

export const handleCheckTerms = (value) => {
	return async (dispatch) =>
		await dispatch({
			type: CHECKED_TERMS,
			payload: {
				values: {
					data: {
						terms: value
					}
				}
			}
		});
};

export const handleStateAffiliation = (name, value) => {
	return async (dispatch) => {
		return await dispatch({
			type: CHANGE_STATE_AFFILIATION,
			payload: {
				values: {
					data: {
						name,
						value
					}
				}
			}
		});
	};
};

export const get_payment_methods = (values) => {
	return async (dispatch) => {
		return await Common.get_payment_methods(values)
			.then((r) => {
				return {
					success: true,
					paymentMethods: r.data.payment_methods,
					promotion: r.data.promotion,
					savingMessage: false
				};
			})
			.catch((e) => {
				return {
					savingMessage: false,
					success: false,
					openAlert: true,
					messageAlert: e?.response?.data?.error?.message
						? e.response.data.error.message
						: 'There was an error consulting the payment methods, please try again.'
				};
			});
	};
};

export const save_userID = (values) => async (dispatch) =>
	await dispatch({
		type: SAVE_USERID,
		payload: {
			values
		}
	});

export const save_token = (values) => async (dispatch) =>
	await dispatch({
		type: SAVE_TOKEN,
		payload: {
			values
		}
	});

export const save_user_type = (values) => async (dispatch) =>
	await dispatch({
		type: SAVE_USER_TYPE,
		payload: {
			values
		}
	});

export const save_titular_email = (values) => async (dispatch) =>
	await dispatch({
		type: SAVE_TITULAR_EMAIL,
		payload: {
			values
		}
	});

export const calculateTotalPayment = (values) => {
	return async (dispatch) =>
		await dispatch({
			type: CALCULATE_TOTAL_PAYMENT,
			payload: {
				values
			}
		});
};

export const useTitularData = (values) => {
	return async (dispatch) =>
		await dispatch({
			type: USE_TITULAR_DATA,
			payload: {
				values
			}
		});
};

export const cleanAffiliation = () => async (dispatch) =>
	await dispatch({ type: CLEAN_AFFILIATION, payload: {} });
export const cleanAffiliationAdmin = () => async (dispatch) =>
	await dispatch({ type: CLEAN_AFFILIATION_ADMIN, payload: {} });

export const sign_up = (values) => {
	return async () => {
		return await Common.sign_up_social(values)
			.then((res) => {
				return {
					savedSuccess: res.data.saved,
					messageAlert: 'Successful affiliate client.',
					savingMessage: false,
					idTitular: res.data._id,
					success: true,
					coupon: res.data.coupon,
					percent_off: res.data.percent_off,
					free_trial: res.data.free_trial
				};
			})
			.catch((e) => {
				if (
					e.response &&
					(e.response.status === 401 || e.response.status === 403)
				) {
					return {
						showRegistryDialog: true,
						savingMessage: false,
						openAlert: true,
						messageAlert:
							e.response && e.response.data
								? e.response.data.error.message
								: 'There was an error saving your information, please try again.',
						success: false
					};
				} else {
					return {
						showRegistryDialog: false,
						savingMessage: false,
						openAlert: true,
						messageAlert:
							e.response && e.response.data
								? e.response.data.error.message
								: 'There was an error saving your information, please try again.',
						success: false
					};
				}
			});
	};
};

export const getProducts = (values) => {
	return async (dispatch) => {
		return await Common.get_plans_by_country(values)
			.then(async (r) => {
				await dispatch({
					type: ALL_PRODUCTS,
					payload: {
						values: r.data.products
					}
				});
				let result = {
					success: true,
					showLoadding: false,
					products: r.data.products,
					options: r.data.options,
					special_services: r.data.special_services,
					payment_methods: r.data.payment_methods
				};
				return result;
			})
			.catch((e) => {
				return {
					success: false,
					showLoadding: false,
					openAlert: true,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'There was an error when retrieving your data, please try again.'
				};
			});
	};
};

export const productsQuotation = (values) => {
	return async (dispatch) => {
		return await Common.products_quotation(values)
			.then(async (r) => {
				return {
					intervals: r.data,
					percent_off: r.data.percent_off,
					success: true
				};
			})
			.catch((e) => {
				return {
					success: false,
					openAlert: true,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'There was an error requesting the information, please try again.'
				};
			});
	};
};

export const sharedQuotation = (values) => {
	return async (dispatch) => {
		return await Common.shared_quotation(values)
			.then(async (r) => {
				return {
					openAlert: true,
					messageAlert: r.data.message,
					success: true
				};
			})
			.catch((e) => {
				return {
					success: false,
					openAlert: true,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'There was an error sending the quote, please try again.'
				};
			});
	};
};

export const getPlansByAge = (values) => {
	return async (dispatch) => {
		return await Common.get_plans_by_age(values)
			.then(async (r) => {
				return {
					success: true,
					products: r.data.products
				};
			})
			.catch((e) => {
				return {
					success: false,
					openAlert: true,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'There was an error requesting your plans, please try again.'
				};
			});
	};
};

export const getProductsByFrequency = (values) => {
	return async (dispatch) => {
		return await Common.products_by_frequency(values)
			.then(async (r) => {
				await dispatch({
					type: SET_ALL_INTERVALS,
					payload: {
						values: {
							intervals: Object.keys(r.data.products[0].intervals)
						}
					}
				});
				return {
					success: true,
					products: r.data.products
				};
			})
			.catch((e) => {
				return {
					success: false,
					openAlert: true,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'There was an error requesting your plans, please try again.'
				};
			});
	};
};

// INTERVAL
export const setInterval = (interval) => (dispatch) =>
	dispatch({
		type: SET_INTERVAL,
		payload: {
			values: {
				interval
			}
		}
	});

export const saveDiscountPercent = (values) => {
	return async (dispatch) =>
		await dispatch({
			type: SHOW_DISCOUNT_PERCENT,
			payload: {
				values
			}
		});
};

export const validateCoupon = (values) => {
	return async (dispatch) => {
		return await Common.validate_coupon(values)
			.then(async (res) => {
				await dispatch({
					type: SHOW_DISCOUNT_PERCENT,
					payload: {
						values: {
							percent_off: res.data.percent_off,
							coupon: res.data.coupon,
							free_trial: res.data.free_trial
						}
					}
				});
				let result = { success: true };
				return result;
			})
			.catch(async (err) => {
				return {
					success: false,
					openAlert: true,
					messageAlert:
						err.response && err.response.data
							? err.response.data.error.message
							: 'There was an error validating the coupon, please try again'
				};
			});
	};
};

export const paymentAffiliation = (values) => {
	return async (dispatch) => {
		return await Common.payment_affiliation(values)
			.then((res) => {
				return {
					success: true,
					spinnerProgress: false,
					statusPayment: res.data.statusPayment,
					paymentResponse: res.data.paymentResponse,
					paymentSubResponse: res.data.paymentSubResponse
				};
			})
			.catch((e) => {
				return {
					success: false,
					spinnerProgress: false,
					statusPayment: false,
					paymentResponse: e?.response?.data?.error?.message
						? e.response.data.error.message
						: 'There was an error processing your payment, please try again.',
					messageAlert: e?.response?.data?.error?.message
						? e.response.data.error.message
						: 'There was an error processing your payment, please try again.',
					openAlert: true
				};
			});
	};
};

export const resendInvitationAlly = (value) => {
	return async (dispatch) => {
		return await Common.resend_email_ally(value)
			.then((res) => {
				return {
					messageAlert: 'Invitation sent successfully',
					openAlert: true
				};
			})
			.catch((e) => {
				return {
					messageAlert:
						'There was an error sending the invitation, please try again.',
					openAlert: true
				};
			});
	};
};

export const clearFormErrorsBeneficiaries = () => async (dispatch) =>
	await dispatch({ type: CLEAN_FORM_ERRORS_BENEFICIARIES, payload: [] });

export const assignAddonToBeneficiary = (values) => async (dispatch) => {
	return await Common.assignAddon(values)
		.then((res) => {
			return {
				success: true,
				customer: res.data.customer,
				payment_methods: res.data.payment_methods,
				showSpinner: false,
				openAlert: true,
				messageAlert: res.data?.message ?? 'successfully assigned.'
			};
		})
		.catch((e) => {
			return {
				success: false,
				showSpinner: false,
				openAlert: true,
				messageAlert: e?.response?.data?.error?.message
					? e.response.data.error.message
					: 'There was an error removing the beneficiary, please try again.'
			};
		});
};

// REMOVE TEMP BENEFICIARIES
export const removeTempBeneficiaries = (beneficiary_sha) => {
	return async (dispatch) => {
		return await Common.remove_temp_beneficiaries({ beneficiary_sha })
			.then(async (response) => {
				return {
					loading: false,
					openAlert: true,
					messageAlert: response?.data?.message
				};
			})
			.catch((e) => {
				return {
					loading: false,
					openAlert: true,
					messageAlert: e?.response?.data?.error?.message
						? e.response.data.error.message
						: 'There was an error removing the beneficiary, please try again.'
				};
			});
	};
};

export const save_is_client = (values) => {
	return async (dispatch) =>
		await dispatch({
			type: SET_IS_CLIENT,
			payload: {
				values
			}
		});
};

export const save_ally_name = (values) => async (dispatch) =>
	await dispatch({
		type: SAVE_ALLY_NAME,
		payload: {
			values
		}
	});

//Accept Terms ADVISER
export const acceptTermsAdviser = () => {
	return async (dispatch) => {
		return Common.accept_terms_conditions()
			.then(async (response) => {
				return {
					success: true
				};
			})
			.catch((e) => {
				return {
					loading: false,
					openAlert: true,
					messageAlert: e?.response?.data?.error?.message
						? e.response.data.error.message
						: 'There was an error accepting your statement.'
				};
			});
	};
};

export const callAdviser = (values) => {
	return async (dispatch) => {
		return Common.notify_adviser(values)
			.then(async (response) => {
				return {
					success: true,
					openAlert: true,
					// showLoading:false,
					messageAlert: 'The Advisor will contact you.'
				};
			})
			.catch((e) => {
				return {
					showLoading: false,
					openAlert: true,
					messageAlert: e?.response?.data?.error?.message
						? e.response.data.error.message
						: 'There was an error contacting the Advisor.'
				};
			});
	};
};

//SAVE-DATA STEP==2

export const saveDataCustomer = (values) => {
	if (values.titular && values.titular.include_titular === true) {
		values.titular.kinship = 'TITULAR';
		values.titular.age = parseInt(values.titular.age);
		if (!values.titular.beneficiary_sha)
			values.titular.beneficiary_sha = getHash();
	}

	if (values.beneficiaries) {
		values.beneficiaries.map((beneficiary) => {
			beneficiary.age = parseInt(beneficiary.age);
			if (!beneficiary.beneficiary_sha) beneficiary.beneficiary_sha = getHash();
			return beneficiary;
		});
	}

	return async (dispatch) => {
		return Common.save_data_customers(values)
			.then((res) => {
				let beneficiaries = res?.data?.beneficiaries;
				beneficiaries.map((b) => (b.formErrors = {}));
				handleStateAffiliation('beneficiaries', beneficiaries);
				return {
					beneficiaries: beneficiaries || values?.beneficiaries,
					titular: res?.data?.titular || values?.titular,
					savedSuccess: res.data.saved,
					savingMessage: false,
					success: true
				};
			})
			.catch((e) => {
				if (
					e &&
					e.response &&
					(e.response.status === 401 || e.response.status === 403)
				) {
					return {
						showRegistryDialog: true,
						savingMessage: false,
						openAlert: true,
						messageAlert:
							e.response && e.response.data
								? e.response.data.error.message
								: 'There was an error saving your information, please try again.',
						success: false
					};
				} else {
					return {
						showRegistryDialog: false,
						savingMessage: false,
						openAlert: true,
						duplicatedError: e.response?.data?.duplicatedError,
						messageAlert:
							e.response && e.response.data
								? e.response.data.error.message
								: 'There was an error saving your information, please try again.',
						success: false
					};
				}
			});
	};
};

// SUMMARY HEALTH DIALOG
export const customerResume = (values) => {
	return async (dispatch) => {
		return await Common.customer_resume(values)
			.then(async (response) => {
				return {
					spinnerProgress: false,
					result: response?.data?.summary,
					subTotal: response?.data?.subTotal,
					disclaimers: response?.data?.disclaimers
				};
			})
			.catch((e) => {
				return {
					spinnerProgress: false,
					openAlert: true,
					messageAlert: e?.response?.data?.error?.message
						? e.response.data.error.message
						: 'There was an error getting the beneficiary summary.'
				};
			});
	};
};

// reload titular data
export const updateReloadTitularData = (values) => async (dispatch) =>
	await dispatch({
		type: UPDATE_TITULAR_DATA,
		payload: {
			values
		}
	});

//SAVE-DATA STEP==3
export const saveHealthData = (values) => {
	return async (dispatch) => {
		return await Common.save_health_data(values)
			.then(async (res) => {
				let beneficiaries = res?.data?.beneficiaries;
				beneficiaries.map((b) => (b.formErrors = {}));
				handleStateAffiliation('beneficiaries', beneficiaries);
				if (res?.data?.titular && res?.data?.titular?.include_titular === true)
					handleStateAffiliation('titular', res?.data?.titular);
				return {
					beneficiaries,
					savingMessage: false,
					success: true
				};
			})
			.catch((e) => {
				return {
					success: false,
					savingMessage: false,
					openAlert: true,
					messageAlert: e?.response?.data?.error?.message
						? e.response.data.error.message
						: 'Hubo un error al guardar los datos de salud.'
				};
			});
	};
};

export const getPaymentMethodsClient = (values) => {
	return async (dispatch) => {
		return await Common.get_payment_methods_client(values)
			.then((r) => {
				return {
					success: true,
					paymentMethods: r.data.payment_methods,
					promotion: r.data.promotion,
					savingMessage: false
				};
			})
			.catch((e) => {
				return {
					savingMessage: false,
					success: false,
					openAlert: true,
					messageAlert: e?.response?.data?.error?.message
						? e.response.data.error.message
						: 'There was an error consulting the payment methods, please try again.'
				};
			});
	};
};

//SAVE HEALTH DATA RENEW
export const saveRenewalHealthData = (values) => {
	return async (dispatch) => {
		return await Common.save_renewal_health_data(values)
			.then(async (res) => {
				let beneficiaries = res?.data?.beneficiaries;
				beneficiaries.map((b) => (b.formErrors = {}));
				handleStateAffiliation('beneficiaries', beneficiaries);
				if (res?.data?.titular && res?.data?.titular?.include_titular === true)
					handleStateAffiliation('titular', res?.data?.titular);
				return {
					beneficiaries,
					success: true,
					loading: false
				};
			})
			.catch((e) => {
				return {
					success: false,
					openAlert: true,
					loading: false,
					messageAlert: e?.response?.data?.error?.message
						? e.response.data.error.message
						: 'Hubo un error al guardar los datos de salud.'
				};
			});
	};
};

export const handleRegisterFree = (name, value) => {
	return async (dispatch) => {
		return dispatch({
			type: REGISTER_FREEMIUM,
			payload: {
				values: value
			}
		});
	};
};
export function getConfig() {
	return async (dispatch) => {
		return Common.country_config()
			.then(async (res) => {
				await dispatch({
					type: SAVE_MOBILE_APPS_LINKS,
					payload: res.data?.mobileAppLinks
				});

				await dispatch({
					type: HAS_ADDONS,
					payload: {
						values: {
							has_addons: res.data.addons
						}
					}
				});
				await setInterval(res.data.productsInterval[0]);
				return {
					success: true,
					mobileAppLinks: res.data?.mobileAppLinks
				};
			})
			.catch((e) => {
				return {
					success: false,
					spinnerScreen: false,
					openAlert: true,
					messageAlert: e?.response?.data?.error?.message
						? e.response.data.error.message
						: 'An error occurred while obtaining the configuration.'
				};
			});
	};
}
