import {
	SAVE_QUOTATION_DATA,
	SAVE_QUOTATION_CLIENT,
	CLEAN_QUOTATION,
	CLEAN_AFFILIATION_ALLY
} from '../actionTypes';
import Common from '../../src/services/common';

export const generateSessionHash = () => {
	return async (dispatch) => {
		return await Common.generate_session_hash()
			.then(async (r) => {
				return {
					hash: r.data.hash,
					success: true
				};
			})
			.catch((e) => {
				return {
					success: false,
					openAlert: true,
					messageAlert: e?.response?.data?.error?.message
						? e.response.data.error.message
						: 'Hubo un error al agregar el nuevo beneficiario, intente nuevamente.'
				};
			});
	};
};

export const saveQuotation = (quotation) => (dispatch) =>
	dispatch({
		type: SAVE_QUOTATION_DATA,
		payload: {
			values: {
				quotation
			}
		}
	});

export const saveQuotationClient = (client) => (dispatch) =>
	dispatch({
		type: SAVE_QUOTATION_CLIENT,
		payload: {
			values: {
				client
			}
		}
	});

export const cleanQuotation = () => async (dispatch) =>
	await dispatch({ type: CLEAN_QUOTATION, payload: {} });

export const cleanAffiliationAlly = () => (dispatch) =>
	dispatch({ type: CLEAN_AFFILIATION_ALLY, payload: {} });
