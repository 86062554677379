import {
	SET_REGION,
	SET_REGIONS,
	SET_CURRENT_COUNTRY,
	SET_COUNTRIES,
	SET_COUNTRY,
	GET_STATES
} from '../actionTypes';
import Common from '../../src/services/common';

export const getAllCountries = (data) => {
	return async (dispatch) => {
		return Common.get_countries(data)
			.then(async (r) => {
				await dispatch({
					type: SET_COUNTRIES,
					payload: r.data.countries
				});
				return { spinnerScreen: false };
			})
			.catch((e) => {
				return {
					spinnerScreen: false,
					openAlert: true,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'Hubo un error al obtener los paises, intente nuevamente.'
				};
			});
	};
};

export const massiveLoadCountries = (data) => {
	return async (dispatch) => {
		return Common.massive_load_countries(data)
			.then((res) => {
				const { registered_countries, tried_countries, invalid_countries } =
					res.data;
				return {
					registered_countries,
					tried_countries,
					invalid_countries,
					success: true,
					spinnerScreen: false,
					showDialogMassive: false,
					showDialogSuccess: true
					// openAlert: true,
					// messageAlert: `Paises cargados ${registered_countries} de ${tried_countries}`,
				};
			})
			.catch((e) => {
				return {
					spinnerScreen: false,
					openAlert: true,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'Hubo un error al registrar los paises, intente nuevamente.'
				};
			});
	};
};

export const createCountry = (data) => {
	return async (dispatch) => {
		return Common.create_country(data)
			.then(() => {
				return {
					success: true,
					spinnerScreen: false,
					showDialogCountry: false,
					openAlert: true,
					messageAlert: 'Pais creado exitosamente.'
				};
			})
			.catch((e) => {
				return {
					spinnerScreen: false,
					openAlert: true,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'Hubo un error al crear el pais, intente nuevamente.'
				};
			});
	};
};

export const updateCountry = (id, data) => {
	return async (dispatch) => {
		return Common.update_country(id, data)
			.then(() => {
				return {
					success: true,
					spinnerScreen: false,
					showDialogCountry: false,
					openAlert: true,
					messageAlert: 'Pais actualizado exitosamente.'
				};
			})
			.catch((e) => {
				return {
					spinnerScreen: false,
					openAlert: true,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'Hubo un error al actualizar el pais, intente nuevamente.'
				};
			});
	};
};

export const deleteCountry = (data) => {
	return async (dispatch) => {
		return Common.delete_country(data)
			.then(() => {
				return {
					success: true,
					spinnerScreen: false,
					showConfirm: false,
					openAlert: true,
					messageAlert: 'Pais eliminado exitosamente.'
				};
			})
			.catch((e) => {
				return {
					spinnerScreen: false,
					openAlert: true,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'Hubo un error al eliminar el pais, intente nuevamente.'
				};
			});
	};
};

export const getAllRegions = () => {
	return async (dispatch) => {
		return Common.get_regions()
			.then(async (r) => {
				await dispatch({
					type: SET_REGIONS,
					payload: r.data.regions
				});
				return { spinnerScreen: false };
			})
			.catch((e) => {
				return {
					spinnerScreen: false,
					openAlert: true,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'Hubo un error al obtener las regiones, intente nuevamente.'
				};
			});
	};
};

export const createRegion = (data) => {
	return async (dispatch) => {
		return Common.create_region(data)
			.then(() => {
				return {
					success: true,
					spinnerScreen: false,
					showDialog: false,
					openAlert: true,
					messageAlert: 'Nueva región registrado exitosamente.'
				};
			})
			.catch((e) => {
				return {
					spinnerScreen: false,
					openAlert: true,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'Hubo un error al crear la región, intente nuevamente.'
				};
			});
	};
};

export const updateRegion = (id, data) => {
	return async (dispatch) => {
		return Common.update_region(id, data)
			.then(() => {
				return {
					success: true,
					spinnerScreen: false,
					showDialog: false,
					openAlert: true,
					messageAlert: 'Región actualizada exitosamente.'
				};
			})
			.catch((e) => {
				return {
					spinnerScreen: false,
					openAlert: true,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'Hubo un error al actualizar la región, intente nuevamente.'
				};
			});
	};
};

export const setCurrentCountry = (country) => async (dispatch) => {
	return dispatch({
		type: SET_CURRENT_COUNTRY,
		payload: country
	});
};

export const setRegionData = (data) => async (dispatch) => {
	return dispatch({
		type: SET_REGION,
		payload: data
	});
};

export const setCountryData = (data) => async (dispatch) => {
	return dispatch({
		type: SET_COUNTRY,
		payload: data
	});
};

/**
 *
 * @returns Coverage Provinces
 */
export const availableStates = () => {
	return async (dispatch) => {
		return Common.available_states()
			.then(async (response) => {
				await dispatch({
					type: GET_STATES,
					payload: {
						values: {
							states: response?.data?.asistensi_states?.map((s) => ({
								name: s.name,
								code: s._id
							}))
						}
					}
				});
				return {
					success: true,
					showLoading: false,
					states: response?.data?.asistensi_states?.map((s) => ({
						name: s.name,
						code: s._id
					}))
				};
			})
			.catch((e) => {
				return {
					showLoading: false,
					openAlert: true,
					messageAlert: e?.response?.data?.error?.message
						? e.response.data.error.message
						: 'There was an error getting the Provinces.'
				};
			});
	};
};

/**
 *
 * @param {number} id
 * @returns Coverage Cities
 */
export const availableMunicipalities = (id) => {
	return async (dispatch) => {
		return Common.available_municipalities(id)
			.then(async (response) => {
				return {
					success: true,
					showLoading: false,
					municipalities: response?.data?.asistensi_municipalities?.map(
						(s) => ({ name: s.name, code: s._id })
					)
				};
			})
			.catch((e) => {
				return {
					showLoading: false,
					openAlert: true,
					messageAlert: e?.response?.data?.error?.message
						? e.response.data.error.message
						: 'There was an error getting the cities.'
				};
			});
	};
};

/**
 *
 * @param {number} id
 * @returns Coverage Barangays
 */
export const availableCities = (id) => {
	return async (dispatch) => {
		return Common.available_cities(id)
			.then(async (response) => {
				return {
					success: true,
					showLoading: false,
					cities: response?.data?.asistensi_cities?.map((s) => ({
						name: s.name,
						code: s._id
					}))
				};
			})
			.catch((e) => {
				return {
					showLoading: false,
					openAlert: true,
					messageAlert: e?.response?.data?.error?.message
						? e.response.data.error.message
						: 'There was an error getting the Barangays.'
				};
			});
	};
};

export function setStates(states) {
	return async (dispatch) => {
		await dispatch({
			type: GET_STATES,
			payload: {
				values: {
					states
				}
			}
		});
		return true;
	};
}

/**
 *
 * @returns All States (coverage + not coverage)
 */
export const searchAllStates = () => {
	return async () => {
		return Common.search_all_states()
			.then((res) => {
				return {
					success: true,
					spinner: false,
					states: res?.data
				};
			})
			.catch((e) => {
				return {
					spinner: false,
					openAlert: true,
					messageAlert: e?.response?.data?.error?.message
						? e.response.data.error.message
						: 'There was an error getting the Provinces, please try again.'
				};
			});
	};
};

/**
 *
 * @returns All Municipalities (coverage + not coverage)
 */
export const searchAllMunicipalities = ({ id }) => {
	return async () => {
		return Common.search_all_municipalities({ id })
			.then((res) => {
				return {
					success: true,
					spinner: false,
					municipalities: res?.data
				};
			})
			.catch((e) => {
				return {
					spinner: false,
					openAlert: true,
					messageAlert: e?.response?.data?.error?.message
						? e.response.data.error.message
						: 'There was an error getting the Cities, please try again.'
				};
			});
	};
};

/**
 *
 * @param {number} idState
 * @returns All Cities (coverage + not coverage)
 */

export const searchAllCities = ({ id }) => {
	return async () => {
		return Common.search_all_cities({ id })
			.then((res) => {
				return {
					success: true,
					spinner: false,
					cities: res?.data
				};
			})
			.catch((e) => {
				return {
					spinner: false,
					openAlert: true,
					messageAlert: e?.response?.data?.error?.message
						? e.response.data.error.message
						: 'There was an error getting the Barangays, please try again.'
				};
			});
	};
};
