import {
	SAVE_GENERAL_DATA,
	SAVE_MOTIVES,
	SAVE_MEDICAL_QUESTIONS,
	MEDICAL_RESULT,
	CLEAN_DATA,
	SAVE_COVID_DATA,
	CLEAN_COVID_DATA
} from '../actionTypes';
import Common from '../../src/services/common.js';

export const saveGeneralData = (values) => {
	return async (dispatch) => {
		return await dispatch({
			type: SAVE_GENERAL_DATA,
			payload: {
				values
			}
		});
	};
};
export const saveCovidData = (values) => {
	return async (dispatch) => {
		return await dispatch({
			type: SAVE_COVID_DATA,
			payload: {
				values
			}
		});
	};
};

export const getMotives = () => {
	return async (dispatch) => {
		return await Common.get_motives()
			.then(async (r) => {
				await dispatch({
					type: SAVE_MOTIVES,
					payload: {
						values: r.data.motives
					}
				});
				let result = {
					showLoadding: false
				};
				return result;
			})
			.catch((e) => {
				return {
					showLoadding: false,
					openAlert: true,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'Hubo un error al consultar la lista de motivos, intente nuevamente.'
				};
			});
	};
};

export const getMedicalQuestions = (id) => {
	return async (dispatch) => {
		return await Common.get_medical_questions(id)
			.then(async (r) => {
				await dispatch({
					type: SAVE_MEDICAL_QUESTIONS,
					payload: {
						values: {
							main_questions: r.data.main_questions,
							secundary_questions: r.data.secundary_questions
						}
					}
				});
				let result = {
					showLoadding: false
				};
				return result;
			})
			.catch((e) => {
				return {
					showLoadding: false,
					openAlert: true,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'Hubo un error al consultar las preguntas de salud, intente nuevamente.'
				};
			});
	};
};

export const sendConsultData = (data) => {
	return async (dispatch) => {
		return await Common.send_consult_data(data)
			.then(async (r) => {
				await dispatch({
					type: MEDICAL_RESULT,
					payload: {
						values: {
							alert: r.data.alert,
							consultationId: r.data.consultationId,
							tips_case_alert: r.data.tips_case_alert,
							general_tips: r.data.general_tips,
							max_days: r.data.max_days
						}
					}
				});
				let result = {
					success: true,
					loadding: false
				};
				return result;
			})
			.catch((e) => {
				return {
					success: false,
					loadding: false,
					openAlert: true,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'Hubo un error al enviar sus datos, intente nuevamente.'
				};
			});
	};
};

export const sendEmail = (data) => {
	return async (dispatch) => {
		return await Common.send_email(data)
			.then(() => {
				return {
					success: true
				};
			})
			.catch((e) => {
				return {
					success: false,
					openAlert: true,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'Hubo un error al enviar el correo, intente nuevamente.'
				};
			});
	};
};

export const sendCode = (data) => {
	return async (dispatch) => {
		return await Common.send_code(data)
			.then(() => {
				return {
					showLoadding: false,
					success: true
				};
			})
			.catch((e) => {
				return {
					success: false,
					showLoadding: false,
					openAlert: true,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'Hubo un error al enviar el código, intente nuevamente.'
				};
			});
	};
};

export const cleanData = () => async (dispatch) =>
	await dispatch({ type: CLEAN_DATA, payload: {} });

export const cleanCovidData = () => async (dispatch) =>
	await dispatch({ type: CLEAN_COVID_DATA, payload: {} });
